import { parseTimeout } from '../duration';
import { Cancelled } from '../errors';

import type { TimeoutSpec, TimeoutOptions } from '../duration';

export type DelayedPromise<T = unknown, P = unknown> = PromiseLike<T> & P & {
  specification: string | number | TimeoutOptions;
  cancel( withRejection?: boolean ): void;
};
export interface DelayOptions<P = unknown> extends TimeoutOptions {
  onCancelation?: () => void;
  props?: P;
}

/**
 * Get a promise that will resolve after the timeout indicated.
 *
 * This promise also has a `.cancel()` method that you can call to
 * cancel the timeout.  By default the cancel will reject with
 * a `Cancelled` error, but you can pass `false` to the cancel method
 * to avoid that.
 *
 * @param time - Any argument that can be parsed by parseTimeout.
 * @returns A promise that resolves after the timeout expires.
 */
export function delay<T=unknown, P=unknown>(
  time_or_opts: TimeoutSpec | DelayOptions,
  add_opts?: DelayOptions,
): DelayedPromise<T, P> {
  const options: DelayOptions = ( typeof time_or_opts === 'object' )
    ? time_or_opts : add_opts || {};
  if ( typeof time_or_opts === 'number' || typeof time_or_opts === 'string' ) {
    options.timeout = time_or_opts;
  }
  const { props = {}, onCancelation, ...opts } = options;
  const ms = parseTimeout( opts );
  let tid = null;
  let rejector = null;
  const p = new Promise( ( resolve, reject ) => {
    tid = setTimeout( resolve, ms );
    rejector = reject;
  } );
  return Object.assign( p, {
    specification : opts,
    cancel( withRejection: boolean = true ) {
      clearTimeout( tid );
      if ( withRejection ) rejector( new Cancelled() );
      if ( onCancelation ) onCancelation();
    },
  }, props ) as unknown as DelayedPromise<T, P>;
}
