import { SSPError } from '../SSPError';

export type RestErrorTags = {
  server_ip?: string;
  server?: string;
};
export type RestErrorData = {
  requestUrl?: ( string | URL );
  finalUrl?: ( string | URL );
  redirectUrls?: ( string | URL )[];
  responseHeaders?: Record<string, string | string[]>;
  responseBody?: any;
  code?: number;
};
export type RestErrorOpts = {
  options?: import( '@ssp/rest' ).OptionsInit;
};

export class RestError extends SSPError<
  RestErrorData, RestErrorTags, RestErrorOpts
> {

  static status = 'rest-error';
  static message = 'REST Error';
  static code = 500;

  declare code: number;
  declare options: import( '@ssp/rest' ).OptionsInit;

  isAcceptableProp( key: string ): boolean {
    return super.isAcceptableProp( key )
      || [ 'options' ].includes( key );
  }

}
