import _ from 'lodash';
import { mkdebug } from './mkdebug';
import pmap from 'p-map';

const debug = mkdebug( 'ssp:utils:emitter' );

const map = new WeakMap();

export type Listener<
  EventData extends { [key: string]: any[] } = Record<string, unknown[]>,
  Name extends AnyEventName<EventData> = keyof EventData | '*',
> = Name extends '*'
  ? ( event: string, ...args: unknown[] ) => void | Promise<void>
  : ( ...args: any[] ) => void | Promise<void>;

function listeners( instance: any, name: string ): Set<Listener>;
function listeners( instance: any ): Map<string, Set<Listener>>;
function listeners( instance: any, name?: string ) {
  if ( ! map.has( instance ) ) map.set( instance, new Map() );
  if ( _.isNil( name ) ) return map.get( instance );
  if ( ! _.isString( name ) ) {
    throw new TypeError( `Emitter: Invalid event name "${name}"` );
  }
  const events = map.get( instance );
  if ( ! events.has( name ) ) events.set( name, new Set() );
  return events.get( name );
}

export type EventName = string;

export type UnsubscribeFn = () => void;
export type AnyEventName<
  EventData extends { [key: string]: any[] },
> = '*' | keyof EventData;
export type EventArgs<
  EventData extends { [key: string]: any[] },
  Name extends AnyEventName<EventData>,
> = EventData[Name];

export interface EmitOptions {
  concurrency?: number;
}

export class Emitter<
  EventData extends Record<string, any[]> = Record<string, unknown[]>,
> {

  static makeEmitter(
    target: any, methods?: string[], overwrite: boolean = false,
  ) {
    debug( 'makeEmitter', target, methods, overwrite );
    ( new Emitter() ).bindTo( target, methods, overwrite );
  }

  on<Name extends AnyEventName<EventData>>(
    name: Name,
    listener: Listener<EventData, Name>,
  ): UnsubscribeFn {
    debug( 'adding listener for', name );
    assertName( name );
    assertListener( listener );
    listeners( this, name ).add( listener );
    return () => this.off.bind( this, name, listener );
  }

  off<Name extends AnyEventName<EventData>>(
    name: Name,
    listener: Listener<EventData, Name>,
  ): void {
    assertName( name );
    assertListener( listener );
    debug( 'removing listener for', name );
    listeners( this, name ).delete( listener );
  }

  once<Name extends AnyEventName<EventData>>(
    name: Name,
  ): Promise<EventData[Name]> {
    debug( 'adding once listener for', name );
    assertName( name );
    return new Promise( resolve => {
      const off = this.on( name, data => {
        off();
        resolve( data );
      } );
    } );
  }

  _emit<Name extends keyof EventData>(
    name: Name, args: EventData[Name], opts: EmitOptions,
  ) {
    debug( 'emitting', name );
    assertName( name );
    assertArgs( args );
    const el = listeners( this, name );
    const al = listeners( this, '*' );
    const els = [ ...el ];
    const als = [ ...al ];
    return Promise.all( [
      pmap( els, async fn => el.has( fn ) && fn( ...args ), opts ),
      pmap( als, async fn => al.has( fn ) && fn( name, ...args ), opts ),
    ] ).then( _.flatten );
  }

  emit<Name extends keyof EventData>(
    name: Name, ...args: EventData[Name]
  ): Promise<any> | any {
    debug( 'emitting', name );
    assertName( name );
    return this._emit( name, args, { concurrency : Infinity } );
  }

  async emitSerial<Name extends keyof EventData>(
    name: Name, ...args: EventData[Name]
  ) {
    debug( 'emitting', name, 'serially' );
    assertName( name );
    return this._emit( name, args, { concurrency : 1 } );
  }

  clearListeners<Name extends keyof EventData>( name?: Name ) {
    debug( 'clearing listeners' );
    if ( name ) {
      assertName( name );
      listeners( this, name ).clear();
    } else {
      for ( const list of listeners( this ).values() ) list.clear();
    }
  }

  listenerCount<Name extends keyof EventData>( name: Name | '*' ) {
    if ( name === '*' ) return listeners( this, name ).size;
    if ( _.isString( name ) ) {
      return listeners( this, '*' ).size + listeners( this, name ).size;
    }

    let count = 0;
    for ( const list of listeners( this ).values() ) count += list.size;
    return count;
  }

  getMeta() {
    const counts = { total : 0 };
    for ( const [ event, list ] of listeners( this ).entries() ) {
      counts[ event ] = list.size;
      counts.total += list.size;
    }
    return { listeners : counts };
  }

  /**
   * Return true if this emitter has listeners.  If `name` is not
   * specified then it will return true if there are any listeners for
   * any event.  If `name` is specified then it will return true if
   * there are listeners for that event (including if there are
   * listeners for `'*'`);
   *
   * @param name - Event name
   */
  hasListeners<Name extends keyof EventData>( name: Name );
  hasListeners( name: '*' );
  hasListeners();
  hasListeners<Name extends keyof EventData>( name?: Name | '*' ) {
    if ( _.isString( name ) && name !== '*' ) {
      const x: Name = name;
      return listeners( this, x ).size > 0 || listeners( this, '*' ).size > 0;
    } else {
      for ( const list of listeners( this ).values() ) {
        if ( list.size > 0 ) return true;
      }
    }
    return false;
  }

  getEmitter() { return this; }
  static getEmitter( tgt ) { return tgt.getEmitter(); }

  bindTo( target: any, methods?: string[], overwrite: boolean = false ) {
    if ( ! methods ) {
      methods = [
        'on', 'off', 'once', 'emit', 'emitSerial', 'getEmitter',
        'hasListeners',
      ];
    }
    for ( const name of methods ) {
      debug( 'binding method', name );
      if ( overwrite || _.isNil( target[ name ] ) ) {
        Object.defineProperty( target, name, {
          enumerable  : false,
          value       : this[ name ].bind( this ),
        } );
      } else {
        throw new Error( `target already has property "${name}"` );
      }
    }
  }

}

function assertName( name: any ): asserts name is string {
  if ( typeof name !== 'string' ) {
    throw new TypeError( 'event name must be a string' );
  }
}
function assertListener( fn: any ): asserts fn is Listener<any, any> {
  if ( typeof fn !== 'function' ) {
    throw new TypeError( 'listener must be a function' );
  }
}
function assertArgs( args: any ): asserts args is any[] {
  if ( ! Array.isArray( args ) ) {
    throw new TypeError( 'event arguments must be an array' );
  }
}
