import _ from 'lodash';

export function arrayify( ...args: ( string | string[] )[] ): string[] {
  args = _.filter( _.compact( _.flattenDeep( args ) ), _.isString );
  args = _.invokeMap( args, 'split', /[\s,]+/u );
  return _.uniq( _.flattenDeep( args ) );
}

/**
 * This type represents options for things like `createComponent`,
 * where the arguments can be `ModelDefinition` or an array of them,
 * possibly recursive, possibly with holes, because of things like
 * `createComponent( standard_opts, BUILD.isServer && server-opts );`
 */
export type Squishy<T>
  = T | undefined | null | false | void | Array<Squishy<T>>;
export type Squished<T, A extends readonly [...any]> = {
  [K in keyof A]: A[K] extends T ? A[K] : never;
}[number];

export function squish<T=unknown>( ...args: Squishy<T>[] ): T[] {
  return _.compact( _.flattenDeep( args ) ) as T[];
}
