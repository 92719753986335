import { delay } from './delay';

export type RetryableFunction = <T=unknown>() => Promise<T>;

export function retry( fn: RetryableFunction, retries: number, wait: string ) {
  return new Promise( ( resolve, reject ) => {
    fn().then( resolve ).catch( error => {
      if ( retries ) {
        return delay( wait ).then( () => {
          return retry( fn, retries - 1, wait ).then( resolve, reject );
        } );
      } else {
        reject( error );
      }
    } );
  } );
}


/*
function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, interval, retriesLeft - 1).then(resolve, reject);
        }, interval);
      });
  });
}
*/
