import _ from 'lodash';
import { toposort, TopoSort, TopoSortOptions } from './toposort';
import { mkdebug } from './mkdebug';
import { Emitter } from './Emitter';
import { deferred, Deferred, background, timeout } from './promise';
import { log } from './log';

import type { Hooks } from './hooks';

const debug = mkdebug( 'ssp:utils:init' );

export type InitPhase = 'init' | 'start' | 'stop' | 'cleanup';
export interface Controllable {
  init?: () => void|Promise<void>;
  start?: () => void|Promise<void>;
  stop?: ( err?: Error|undefined ) => void|Promise<void>;
}
export interface Initializable extends Controllable {
  id?: string;
  before?: string|string[];
  after?: string|string[];
  start_before?: string|string[];
  start_after?: string|string[];
  stop_before?: string|string[];
  stop_after?: string|string[];
  init_before?: string|string[];
  init_after?: string|string[];
  context?: Controllable;
  hooks?: Hooks;
}
export type InitProgressItem = {
  id: string;
  type: string;
  method: InitPhase;
  complete: boolean;
};
export type Invokable = InitProgressItem & {
  func: () => Promise<void>;
};
export type InitProgress = {
  total: number;
  complete: number;
  remaining: number;
  index: number;
  pct: number;
  last?: InitProgressItem;
  next?: InitProgressItem;
  items: InitProgressItem[];
};

export class Init extends Emitter {

  inited: boolean = false;
  started: boolean = false;
  stopped: boolean = false;
  contexts: Map<Controllable, Initializable> = new Map();
  starters: Map<() => void|Promise<void>, Initializable> = new Map();
  after_watchers: Record<string, Deferred> = {};
  status_info: Record<string, any> = {};
  timeout: number | string = '90s';

  #items: Set<Initializable> = new Set();

  constructor() {
    super();
    if ( typeof SSP !== 'undefined' ) {
      const inits = ( SSP as any ).init_instances;
      if ( inits instanceof Set ) inits.add( this );
    }
  }

  destroy() {
    if ( typeof SSP !== 'undefined' ) {
      const inits = ( SSP as any ).init_instances;
      if ( inits instanceof Set ) inits.delete( this );
    }
  }

  register( item: Initializable ) {
    if ( ! _.isPlainObject( item ) ) {
      throw new TypeError( `init.register argument must be plain object` );
    }

    const id = this.getId( item );

    if ( item.context ) {
      if ( this.contexts.has( item.context ) ) {
        const old = this.getId( this.contexts.get( item.context ) );
        throw new Error(
          `init can't register ${id}, context was already registered by ${old}`,
        );
      }
      this.contexts.set( item.context, item );
    }
    if ( item.start ) {
      if ( this.starters.has( item.start ) ) {
        const old = this.getId( this.starters.get( item.start ) );
        throw new Error(
          `init can't register ${id}, start was already registered by ${old}`,
        );
      }
      this.starters.set( item.start, item );
    }
    debug( 'REGISTER:', item );
    this.#items.add( item );

    if ( this.inited ) {
      background( this.invoke_one( item, 'init' ), {
        label : 'init.register (already inited)',
      } );
    }
    if ( this.started ) {
      background( this.invoke_one( item, 'start' ), {
        label : 'init.register (already started)',
      } );
    }
  }

  getId( item: Initializable ) { return item.id; }

  items( when: InitPhase, opts: TopoSortOptions = {} ): Initializable[] {
    const graph: TopoSort = toposort( Array.from( this.#items ), {
      ...opts,
      getId     : this.getId,
      getBefore : item => ( [ item.before, item[ `${when}_before` ] ] ),
      getAfter  : item => ( [ item.after, item[ `${when}_after` ] ] ),
      result    : 'graph',
    } );
    this[ `${when}_graph` ] = graph;
    debug( `INIT ORDER (${when}):`, ...graph.all_ids );
    this.status_info[ `${when}_order` ] = graph.all_ids;
    this.status_info[ `${when}_nodes` ] = _.mapValues( graph.nodes, v => {
      return Array.from( v ).join( ' ' );
    } );
    return graph.items;
  }

  async invoke_one( item: Initializable, method: InitPhase, ...args: any[] ) {
    this.invoke_these( this.invokable( item, method, ...args ), false );
  }

  async invoke_these(
    invokables: Invokable[], emit_progress: boolean = false,
  ) {
    const total = invokables.length;
    let complete = 0;
    for ( const [ index, invokable ] of Object.entries( invokables ) ) {
      await invokable.func();
      invokable.complete = true;
      complete++;
      if ( emit_progress ) {
        this.emit( 'progress', {
          total, complete, remaining : total - complete, index,
          pct   : Math.floor( ( complete / total ) * 100 ),
          last  : invokable,
          next  : invokables[ index + 1 ],
          items : invokables.map( i => _.pick( i, [
            'method', 'type', 'id', 'complete',
          ] ) ),
        } );
      }
    }
  }

  invokables( method: InitPhase, ...args: any[] ): Invokable[] {
    return this.items( method )
      .flatMap( item => this.invokable( item, method, ...args ) );
  }

  invokable(
    item: Initializable, method: InitPhase, ...args: any[]
  ): Invokable[] {
    const METHOD = method.toUpperCase();
    const res: Invokable[] = [];
    const id = this.getId( item );
    const add = ( type: string, fn: () => Promise<any> ) => {
      const func = async (): Promise<void> => {
        debug( `>>>>> ${METHOD} ${id} ${type} <<<<<  ` );
        await timeout( this.timeout, Promise.resolve( fn() ), {
          message : `${METHOD} ${id} timed out (${type})`,
        } );
      };
      res.push( { method, type, id, func, complete : false } );
    };
    if ( typeof item[ method ] === 'function' ) {
      add( 'ITEM', () => item[ method ].call( item.context, ...args ) );
    }
    if ( typeof item.context?.[ method ] === 'function' ) {
      add( 'CONTEXT', () => item.context[ method ]() );
    }
    if ( item.hooks?.has?.( method ) ) {
      add( 'HOOKS', () => item.hooks.call( method ) );
    }
    if ( method === 'start' && this.after_watchers[ id ] ) {
      add( 'AFTER_WATCHERS', async () => this.after_watchers[ id ]( id ) );
    }
    return res;
  }

  /** Return a promise that resolves after the given id is started. */
  after( id ) {
    return this.after_watchers[ id ]
      || ( this.after_watchers[ id ] = deferred() );
  }

  async invoke( method, ...args ) {
    return this.invoke_these( this.invokables( method, ...args ) );
  }

  async init() {
    debug( '>>>>>>>>>>>>>>> INIT <<<<<<<<<<<<<<<' );
    if ( this.inited ) return;
    this.inited = true;
    return this.invoke( 'init' );
  }

  async start() {
    debug( '>>>>>>>>>>>>>>> START <<<<<<<<<<<<<<<' );
    if ( this.started ) return;
    const items: Invokable[] = [];
    if ( ! this.inited ) {
      items.push( ...this.invokables( 'init' ) );
      this.inited = true;
    }
    items.push( ...this.invokables( 'start' ) );
    this.started = true;
    debug( 'ALL INVOKABLES:', items );
    return this.invoke_these( items, true );
  }

  async stop( error?: Error ) {
    debug( '>>>>>>>>>>>>>>> STOP <<<<<<<<<<<<<<<' );
    if ( error ) {
      log.error( 'Init stopping because of error:', error.stack );
    }
    if ( error ) debug( 'ERROR:', error );
    if ( this.stopped || ! this.started ) return;
    this.stopped = true;
    await this.invoke( 'stop', error );
    await this.invoke( 'cleanup' );
  }

  async cleanup() {
    debug( '>>>>>>>>>>>>>>> CLEANUP <<<<<<<<<<<<<<<' );
    if ( ! this.stopped ) return;
    return this.invoke( 'cleanup' );
  }

  status() {
    return {
      ...this.status_info,
      // traps   : this.traps,
      inited  : this.inited,
      started : this.started,
      stopped : this.stopped,
    };
  }

  /*
  get traps() { return this._traps; }
  set traps( traps ) {
    if ( BUILD.isServer ) {
      if ( traps && ! this._traps ) {
        process.on( 'exit', this.onExit.bind( this ) );
        process.on( 'SIGINT', this.onSIGINT.bind( this ) );
        this._traps = true;
      }
      if ( this._traps && ! traps ) {
        process.off( 'exit', this.onExit.bind( this ) );
        process.off( 'SIGINT', this.onSIGINT.bind( this ) );
        this._traps = false;
      }
    }
  }
  */

  onExit() {
    debug( 'process.on( exit )' );
    this.stop();
  }

  /*
  #tried_int: boolean = false;
  onSIGINT() {
    debug( 'process.on( SIGINT )' );
    if ( this.#tried_int ) {
      if ( BUILD.isServer ) {
        if ( _.isFunction( process.exit ) ) process.exit( 100 );
      }
    } else {
      this.stop();
      this.tried_int = true;
    }
  }
  */

}

export const init = new Init();
