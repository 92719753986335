import { stripAnsi } from './colors';
import _emojiregex from 'emoji-regex';
import { eastAsianWidth } from 'eastasianwidth';

const emojiregex = _emojiregex();

export function stringWidth( str: string ): number {
  if ( typeof str !== 'string' ) return 0;

  str = stripAnsi( str );
  if ( str.length === 0 ) return 0;

  str = str.replace( emojiregex, '  ' );

  let width = 0;

  for ( let i = 0 ; i < str.length ; i++ ) {
    const cp = str.codePointAt( i );

    // Ignore control characters
    if ( cp <= 0x1F || ( cp >= 0x7F && cp <= 0x9F ) ) continue;

    // Ignore combining characters
    if ( cp >= 0x300 && cp <= 0x36F ) continue;

    width += [ 'F', 'W' ].includes( eastAsianWidth( str.charAt( i ) ) ) ? 2 : 1;
  }

  return width;
}

export function indent( text: string|string[], spaces=2 ) {
  if ( Array.isArray( text ) ) {
    return text.map( t => indent( t, spaces ) );
  } else if ( typeof text === 'string' ) {
    return text.replace( /^/gum, ' '.repeat( spaces ) );
  } else {
    return text;
  }
}
export function dedent( text: string|string[], spaces=2 ) {
  if ( Array.isArray( text ) ) {
    return text.map( t => dedent( t, spaces ) );
  } else if ( typeof text === 'string' ) {
    return text.split( '\n' ).map( x => x.slice( spaces ) ).join( '\n' );
  } else {
    return text;
  }
}
