import _ from 'lodash';
import { mkdebug } from '../mkdebug';

const stores: $TSFixMe = {};

export function get( type: string, name: string ) {
  try {
    return stores[ ( name as $TSFixMe ).name || name ].public;
  } catch ( err ) {
    throw new Error( `${type} ${name} does not exist` );
  }
}

let counter = 0;

/**
 * The generic `State` is the shape of the data in the store.  The generic
 * `Public` is the shape of the added public interface (if any).
 */
export type StorePublic<
  State=unknown,
  Public extends Record<string, any> = Record<string, unknown>,
> = Public & {
  /** The name of the store */
  name: string;
  /** The human-readable description of the store. */
  description?: string;
  /**
   * Start watching a store for changes.  Callback will be called
   * with the new state whenever the state of the store changes.
   *
   * @returns A disposer function to unsubscribe from the watch.
   */
  watch( cb: ( state: State ) => void ): () => void;
  /** Get the current state of the store without watching it. */
  current(): State | undefined;
};
type StoreInternal<
  State=unknown,
  Public extends Record<string, any> = Record<string, unknown>,
> = {
  public: StorePublic<State, Public>;
  state: State;
  setters: Map<any, ( val: State ) => void>;
  unwatch( id: string ): void;
  debug( ...args: any[] ): void;
  initialize?: () => State;
  [key: string]: unknown;
} & StorePublic<State, Public>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface CreateOptions<
  State=unknown,
  Public extends Record<string, any> = Record<string, unknown>,
> {
  /** Store name */
  name: string;
  /** Description, mostly for debugging tools. */
  description?: string;
  /** Additional methods or properties to add to the public interface. */
  public?: Partial<Public>;
  /** Function to produce initial state. */
  initialize?: () => State;
  /** Initial state. */
  state?: State;
  [key: string]: unknown;
}
export function create<
  State=unknown,
  Public extends Record<string, any> = Record<string, unknown>,
>( opts: CreateOptions<State, Public> ): StorePublic<State, Public> {
  if ( ! _.isString( opts.name ) ) {
    throw new Error( `store name must be a string` );
  }
  if ( stores[ opts.name ] ) {
    throw new Error( `store "${opts.name}" already exists` );
  }
  const debug = mkdebug( `ssp:utils:stores:${opts.name}` );

  const store: StoreInternal<State, Public> = {
    ...opts,
    ...( opts.public || {} ),
    setters: new Map(),
    watch( cb ) {
      const id = `watcher-${counter++}`;
      this.setters.set( id, cb );
      cb( this.state );
      debug( 'Added setter %s, now have %d', id, this.setters.size );
      return () => this.unwatch( id );
    },
    unwatch( id ) {
      this.setters.delete( id );
      debug( 'Removed setter %s, %d remain', id, this.setters.size );
    },
    current() { return this.state; },
    debug,
  } as unknown as StoreInternal<State, Public>;

  _.assign( store, opts.public );
  const x = store as any;
  for ( const key of Object.keys( x ) ) {
    if ( _.isFunction( x[key] ) ) x[ key ] = x[key].bind( x );
  }

  const keys: ( keyof StorePublic<State, Public> )[] = [
    'name', 'description', 'current', 'watch',
    ...Array.from( Object.keys( opts.public ) ),
  ] as ( keyof StorePublic<State, Public> )[];
  store.public = _.pick( store, keys ) as unknown as StorePublic<State, Public>;

  if ( _.isFunction( store.initialize ) ) store.state = store.initialize();

  stores[ store.name ] = store;
  return store.public;
}
