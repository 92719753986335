import _ from 'lodash';

export interface TypeRegistration<
  ValueType = unknown, EncodedType = unknown
> {
  key: string;
  encode( value: ValueType ): EncodedType|undefined|null|false;
  decode( value: EncodedType ): ValueType;
}

export function isTypeRegistration( val: any ): val is TypeRegistration {
  return _.isPlainObject( val )
    && _.isString( val.key )
    && _.isFunction( val.encode )
    && _.isFunction( val.decode );
}

const jsonTypeRegistry: TypeRegistration[] = [];

export function getJsonType( key: string ) {
  for ( const reg of jsonTypeRegistry ) {
    if ( reg.key === key ) return reg;
  }
}

export function addJsonType<T = any, E = any>(
  opts: TypeRegistration<T, E>,
): void {
  const { key, encode, decode } = opts;
  if ( ! _.isString( key ) ) {
    throw new TypeError( `addJsonType requires a key` );
  }
  if ( ! _.isFunction( encode ) ) {
    throw new TypeError( `addJsonType requires an encode function` );
  }
  if ( ! _.isFunction( decode ) ) {
    throw new TypeError( `addJsonType requires a decode function` );
  }
  if ( getJsonType( key ) ) {
    throw new Error(
      `JSON type registry already has a type with key "${key}"`,
    );
  }
  jsonTypeRegistry.push( opts );
}

export function encodeJsonType( input: any ): [ string, any ] | undefined {
  // Does it have a type that we can serialize to a reference?
  for ( const reg of jsonTypeRegistry ) {
    const val = reg.encode( input );
    if ( ! _.isNil( val ) ) {
      return [ reg.key, val ];
    }
  }
}
