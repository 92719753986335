import _ from 'lodash';

import { log } from '../log';
import { yamlDump } from '../yaml';
import { isString, isRecord } from '../types';
import { mkdebug } from '../mkdebug';
import { hideProps } from '../props';
import { scrubSecrets } from '../secrets';
import { indent } from '../strings';
import { Stack } from '../stacks';
import { squish } from '../arrays';

import { isAnyError } from './AnyError';
import { findErrorConstructor } from './registry';
import { getErrorType } from './serialization';
import { extractErrorProperties } from './extract';

import type { UData } from '@ssp/ts';

import type { Squishy } from '../arrays';

import type { AnyError } from './AnyError';
import type { Errors } from './validation';

import type {
  SSPErrorOptions, ErrorData, ErrorTags, ErrorOpts,
  SSPErrorConstructor, AugmentError,
} from './types';

export function simplifyError( error: AnyError|string ) {
  return SSPError.simplify( error );
}
export type SimplifiedErrorStrings = string | string[];
export type SimplifiedErrorArray = SimplifiedError[];
export type SimplifiedErrorObject = { [key: string]: SimplifiedError; };
export type SimplifiedError =
  | SimplifiedErrorStrings
  | SimplifiedErrorArray
  | SimplifiedErrorObject;
export type SSPErrorDetails = {
  message?: string;
  index?: string;
  cause?: string;
  tags?: string;
  flags?: string;
  data?: string;
  stack?: string;
  reproduction?: string;
};

const error_details = mkdebug( 'error-details', {
  description : 'Include detail information in error messages',
  env         : 'SSP_ERROR_DETAILS',
} );
const common_props = [
  'message', 'tags', 'data', 'code', 'status', 'label', 'cause',
  'errors', 'index', 'reproduction',
] as const;

// These are properties that will be included when the error is serialized
const serializable_props = [
  ...common_props, 'name', 'warnings', '_message', '_stack', '_wrap_stack',
] as const;

// These are properties that can be set with `error.setValue`.
const acceptable_props = [
  ...common_props,
  'thrower', 'wrapper', 'name', 'props',
] as const;

export const ERROR_LEVELS = [ 'info', 'warn', 'error', 'fatal', 'unknown' ];
export type ErrorLevel = typeof ERROR_LEVELS[number];
export function isErrorLevel( value: any ): value is ErrorLevel {
  return ERROR_LEVELS.includes( value );
}

export type AnySSPError = SSPError<ErrorData, ErrorTags, ErrorOpts>;

export function isSSPError( value: any ): value is AnySSPError {
  return value instanceof SSPError;
}

export abstract class SSPError<
  Data extends ErrorData, // Note that abstract error classes should not
  Tags extends ErrorTags, // Provide defaults for Data or Tags
  // eslint-disable-next-line @typescript-eslint/ban-types
  Opts extends ErrorOpts, // Additional Options
  Options = SSPErrorOptions<Data, Tags, Opts>
> extends Error implements AnyError {

  // All of these static props are simply defaults based on the error
  // class
  static message?: string;
  static code?: number;
  static status?: string;
  static data?: ErrorData;
  static tags?: ErrorTags;
  static level?: ErrorLevel;
  static log_details?: boolean;
  static report_to_sentry?: boolean;

  /**
   * Simplify an error, attempting to reduce it to it's most basic form.
   * This is primarily used by things like the final-form handlers.
   *
   * @param error - The error to simplify.
   */
  static simplify( error: AnyError|string ): SimplifiedError {
    if ( _.isNil( error ) ) return;
    if ( _.isString( error ) ) return error;
    if ( _.isArray( error ) ) {
      const res = _.compact( _.map( error, SSPError.simplify ) );
      return _.isEmpty( res ) ? undefined : res;
    }
    if ( _.isPlainObject( error ) ) {
      const res = _.omitBy( _.mapValues( error, SSPError.simplify ), _.isNil );
      return _.isEmpty( res ) ? undefined : res;
    }
    if ( error instanceof SSPError ) return error.simplify();

    // Fallthrough - hope for the best...
    return String( error ).split( '\n' )[0];
  }

  is_temporary: boolean = false;
  is_not_found: boolean = false;
  is_unknown: boolean = false;
  is_unique_key_conflict: boolean = false;

  declare sentry_id?: string;

  /** Error label. */
  declare label: string;

  /** Just the message, without added information. */
  declare _message: string;

  /** The error message. */
  declare message: string;

  /** Just the stack, without added information. */
  declare _stack: Stack;

  /** Error stack. */
  declare stack: string;

  /** Logging tags. */
  tags: Tags = {} as Tags;

  /** Any other data that goes with this error. */
  data: Data = {} as Data;

  /** HTTP status code. */
  declare code?: number;

  /** String status code. */
  declare status?: string;

  /** Error name. */
  declare name: string;

  /** The error that caused this one. */
  declare cause?: AnyError;

  /** A command line to attempt to reproduce this error. */
  declare reproduction?: string;

  /**
   * Additional errors associated with this one (probably from validation).
   */
  errors?: AnyError[] = [];

  /** Index or property name of a child error. */
  declare index?: string|number;

  /**
   * The function that threw the error, the captured stack trace will
   * omit this function and everything that follows it.
   */
  declare thrower?: ( ...args: any[] ) => any;

  declare readonly _debug: ReturnType<typeof mkdebug>;

  /** The "level" of the error.  */
  declare level?: ErrorLevel;

  /**
   * The "severity" of the error. This is simply the `level`, but as
   * a number, so you can compare it to a threshold.
   */
  get severity() { return SSPError.severity( this.level ); }

  /** Whether or not to include details when logging this error. */
  declare log_details?: boolean;

  /** Whether this error should be reported to Sentry. */
  report_to_sentry: boolean = true;

  /**
   * Create a new error by wrapping an external error.
   * The returned error will use the wrapped errors message, code,
   * status, or statusCode fields as defaults for those properties.
   */
  static wrap( error: AnyError, opts: SSPErrorOptions = {} ) {
    const props = this.extractErrorProperties( error );
    const Err = this.findConstructor( opts, props )
      || getErrorType( 'UnknownError' );
    return new Err( { ...opts, ...props, cause : error } );
  }
  static extractErrorProperties( error: AnyError ) {
    return extractErrorProperties( error );
  }
  static findConstructor(
    ...args: Parameters<typeof findErrorConstructor>
  ): SSPErrorConstructor {
    return findErrorConstructor( ...args ) as SSPErrorConstructor;
  }

  /**
   * Check whether the value provided is a constructor that will
   * construct an instance of an SSPError subclass.
   */
  static isConstructor<T extends typeof SSPError>(
    this: T, value: any,
  ): value is T {
    return typeof value === 'function'
      && typeof value.prototype === 'object'
      && value.prototype instanceof SSPError;
  }

  static is<T extends typeof SSPError>(
    this: T, val: any,
  ): val is InstanceType<T> {
    return val instanceof SSPError;
  }

  static severity( level: ErrorLevel ) {
    if ( ! isErrorLevel( level ) ) return ERROR_LEVELS.indexOf( 'unknown' );
    return ERROR_LEVELS.indexOf( level );
  }

  constructor( message: string, options?: Omit<Options, 'message'> );
  constructor( cause: AnyError, options?: Omit<Options, 'cause'> );
  constructor( options?: Options );
  constructor( arg1?: string | Options | AnyError, arg2?: Options ) {
    super();
    hideProps( this, { _debug : mkdebug( `ssp:errors:${this.name}` ) } );
    this.name = new.target.name;
    for ( const [ key, value ] of Object.entries( new.target ) ) {
      if ( key.startsWith( 'is_' ) && _.isBoolean( value ) ) {
        this[ key ] = value;
      }
    }

    if ( _.isString( arg1 ) ) {
      if ( isRecord( arg2 ) ) {
        const { cause, ...opts } = arg2;
        if ( isAnyError( cause ) ) this.addCause( cause );
        this.merge( opts );
      }
      // Make sure the message is set after the cause, so that it overrides
      this.setValue( 'message', arg1 );
    } else if ( isAnyError( arg1 ) ) {
      this.addCause( arg1 );
      this.merge( arg2 );
    } else if ( isRecord( arg1 ) ) {
      const { cause, ...opts } = arg1;
      if ( isAnyError( cause ) ) this.addCause( cause );
      this.merge( opts );
    }

    this.mergeContext();

    if ( new.target.data ) this.addData( new.target.data as Data );
    if ( new.target.tags ) this.addTags( new.target.tags as Tags );

    this.initialize();

    if ( ! this._stack ) {
      this._stack = Stack.capture( this.thrower || this.ctor );
    }

    _.defaults( this, {
      _message          : new.target.message
        || `Unknown Error: ${_.startCase( this.name )}`,
      code              : new.target.code,
      status            : new.target.status,
      level             : new.target.level,
      log_details       : new.target.log_details,
      report_to_sentry  : new.target.report_to_sentry,
    } );
    if ( ! _.isBoolean( this.report_to_sentry ) ) {
      this.report_to_sentry = this.severity > SSPError.severity( 'warn' );
    }

    this._constructed = true;
    this.rebuild();
    this.debug();
    if ( this.warnings.length ) {
      log.warn( 'SSPError constructed with warnings:', this.warnings );
    }
  }
  declare _wrap_stack?: Stack;
  get wrap_stack() { return this._wrap_stack?.toString(); }

  initialize() { /* no-op */ }

  get ctor() { return Object.getPrototypeOf( this ).constructor; }

  addCause( error: AnyError ) {
    if ( this.cause ) {
      this.warn( `Got duplicate cause argument: ${error}` );
      return this.addError( error );
    }
    this.cause = error;
    this.merge( this.ctor.extractErrorProperties( error ) );
    this._wrap_stack = Stack.capture();
    this._stack = Stack.fromError( error );
  }

  addData( data: Record<string, any> ) { _.defaults( this.data, data ); }
  addTags( tags: Tags ) { _.defaults( this.tags, tags ); }
  addProps( props: Opts ) { hideProps( this, props ); }

  isAcceptableProp( key: string ): boolean {
    return key.startsWith( 'is_' ) || acceptable_props.includes( key as any );
  }
  setValue( key: string, value: any, soft: boolean = false ) {
    if ( ! this.isAcceptableProp( key as any ) ) {
      log.warn( `${this.name} given unknown property '${key}':`, value );
      return ( this.data as any )[ key ] = value;
    }
    if ( key === '_message' ) key = 'message';
    if ( key === 'tags' ) return this.addTags( value );
    if ( key === 'data' ) return this.addData( value );
    if ( key === 'cause' ) return this.addCause( value );
    if ( key === 'errors' ) return this.addErrors( value );
    if ( key === 'props' ) return this.addProps( value );
    if ( key === 'message' ) {
      this._message = value;
    } else {
      this[ key ] = value;
    }
    if ( ! soft ) this.rebuild();
  }

  merge( source: Record<string, any>, soft: boolean = false ) {
    if ( ! source ) return;
    for ( const [ key, value ] of Object.entries( source ) ) {
      this.setValue( key, value, true );
    }
    if ( ! soft ) this.rebuild();
  }

  mergeContext() {
    if ( typeof globalThis.ctx !== 'undefined' ) {
      _.defaults( this.tags, globalThis.ctx.tags );
    }
  }

  addError( err: AnyError ) {
    if ( isAnyError( err ) ) this.errors.push( err );
  }

  addErrors( errs: Errors ) {
    if ( ! errs ) return;
    this.errors.push( ..._.flatMap( errs, ( arg, index ) => {
      if ( ! ( isString( arg ) || isAnyError( arg ) ) ) return [];
      const error: AnyError = isAnyError( arg ) ? arg : new Error( arg );
      error.index = index;
      return error;
    } ) );
  }

  warnings: string[] = [];
  warn( ...msg: any[] ) {
    this.warnings.push( msg.join( ' ' ) );
  }

  throw() { throw this; } // eslint-disable-line no-throw-literal

  toString() { return this.message; }

  _constructed = false;
  rebuild() {
    if ( ! this._constructed ) return;
    this.rebuildMessage();
    this.rebuildStack();
  }
  rebuildMessage() {
    if ( error_details.enabled ) {
      this.message = this.formatDetails();
    } else {
      this.message = this.formatMessage();
    }
  }
  rebuildStack() {
    this.stack = this.message + '\n\n' + this._stack.toString();
  }

  simplify(): SimplifiedError {
    if ( this.errors?.length ) {
      const res: SimplifiedError = { '*' : [] };
      res[ '*' ] = this._message;
      for ( const err of this.errors ) {
        res[ err.index ?? '???' ] = SSPError.simplify( err );
      }
      return res;
    } else {
      return this._message;
    }
  }

  debug( ...msg: any[] ) {
    if ( ! this._debug.enabled ) return;
    // @ts-ignore
    if ( msg.length ) {
      this._debug( msg[0], ...msg.slice( 1 ) );
    } else {
      this._debug( this.formatDetails() );
    }
  }

  formatOnlyMessage() {
    let msg = this._message;

    if ( this.index ) msg = String( this.index ) + ':' + msg;

    const stat = _.compact( _.at( this, 'label', 'status', 'code' ) );
    if ( stat.length ) msg += ` (${stat})`;

    return msg;
  }

  formatMessage() {
    const res: string[] = [ this.formatOnlyMessage() ];
    if ( this.cause ) {
      res.push( indent(
        'Caused by: ' + String( this.cause.message || this.cause ),
      ) );
    }
    if ( this.errors ) {
      for ( const child of this.errors ) {
        const m = String( child._message || child.message || child );
        const i = child.index;
        res.push( indent( _.isNil( i ) ? m : `${i}: ${m}` ) );
      }
    }
    return res.join( '\n' );
  }

  formatDetails() {

    const { message, ...details } = this.getDetails();

    const labels = {
      cause         : 'Caused By',
      tags          : 'Error Tags',
      flags         : 'Error Flags',
      data          : 'Error Data',
      stack         : 'Stack Trace',
      reproduction  : [
        '## Reproduction Command ##',
        '',
        '> Attempt to reproduce this error by running this command:',
      ].join( '\n' ),
    };
    const output: string[] = [ `# ${message} #`, '' ];
    for ( const [ key, content ] of Object.entries( details ) ) {
      const label = labels[ key ] || key;
      output.push( label.includes( '\n' ) ? label : `## ${label} ##`, '' );
      output.push( content, '' );
    }

    const children = this.getChildDetails();
    if ( children?.length ) {
      output.push( '## Children ##', '', ...children.flatMap( child => {
        const { message : msg, ...dets } = child;
        return [
          `### ${msg} ###`, '',
          ...Object.entries( dets ).flatMap( ( [ k, v ] ) => ( [
            `#### ${labels[ k ] || k} ####`, '', String( v ), '',
          ] ) ),
        ];
      } ) );
    }
    return output.join( '\n' );
  }

  getDetails(): SSPErrorDetails {

    const details: SSPErrorDetails = {
      message   : this.formatOnlyMessage(),
    };

    const { index, cause, flags, data, tags, stack } = this;
    if ( ! _.isEmpty( flags ) ) details.flags = flags.join( ', ' );
    if ( index ) details.index = String( index );
    if ( cause ) details.cause = String( cause );
    if ( ! _.isEmpty( data ) ) details.data = yamlDump( data );
    if ( ! _.isEmpty( tags ) ) details.tags = yamlDump( tags );
    if ( stack ) details.stack = String( stack );
    /*
    if ( this._stack ) {
      if ( this._stack.formatDetails ) {
        details.stack = this._stack.formatDetails();
      } else {
        log.debug( 'STACK:', this._stack );
      }
    }
    */

    if ( this.reproduction ) {
      details.reproduction = [
        '```sh', this.reproduction.trim(), '```',
      ].join( '\n' );
    }
    return _.mapValues(
      _.omitBy( details, _.isNil ),
      val => scrubSecrets( val ).trim(),
    );
  }

  getChildDetails(): SSPErrorDetails[] {
    return this.errors?.map( err => {
      if ( isSSPError( err ) ) return err.getDetails();
      return {
        message : scrubSecrets( String( err.message ) ),
        stack   : scrubSecrets( String( err.stack ) ),
      };
    } );
  }

  get flag_keys() {
    return _.keys( this ).filter( key => {
      return key.startsWith( 'is_' )
        && _.isBoolean( this[ key ] )
        && this[ key ];
    } );
  }

  get serializable_keys() {
    return _.uniq( [
      ...serializable_props,
      ...this.flag_keys,
    ] );
  }
  get flags() {
    return this.flag_keys.map( key => key.slice( 3 ) );
  }

  getSerializableData(): UData {
    return _.pick( this, this.serializable_keys );
  }

  augment( ...augments: Squishy<AugmentError>[] ) {
    for ( const augmenter of squish( augments ) ) {
      if ( _.isFunction( augmenter ) ) {
        this.augment( augmenter( this ) );
      } else if ( _.isPlainObject( augmenter ) ) {
        this.merge( augmenter );
      }
    }
    return this;
  }

  // istanbul ignore next
  [Symbol.for( 'nodejs.util.inspect.custom' )]( depth, options ) {
    const msg = options.stylize(
      `SSPError[${this.name}]<${this._message}>`,
      'special',
    );
    if ( depth < 0 ) return msg;

    return msg + '\n\n' + this.formatDetails();
  }
  // Mocha uses this when displaying errors
  inspect() { return this.formatDetails(); }

  ctxval( ...keys: string[] ) {
    for ( const key of keys ) {
      const val = this.data?.[ key ]
        ?? this.tags?.[ key ]
        ?? this.data.context?.[ key ];
      if ( ! _.isNil( val ) ) return val;
    }
  }

  get schema() { return this.ctxval( 'schema' ); }
  get param() { return this.field; }
  get field() {
    const val = this.ctxval( 'field', 'param' );
    if ( ! _.isNil( val ) ) return val;
    if ( typeof this.index === 'string' ) return this.index;
  }

  getSentryData() {
    /*
    const nativeKeys = [
      'name', 'message', 'stack', 'line', 'column',
      'fileName', 'lineNumber', 'columnNumber',
    ];
    const common_props = [
      'message', 'tags', 'data', 'code', 'status', 'label', 'cause',
      'errors', 'index', 'reproduction',
    ] as const;
    const serializable_props = [
      ...common_props, 'name', 'warnings', '_message', '_stack', '_wrap_stack',
    ] as const;
    */

    const data = _.mapKeys(
      _.pick( this, serializable_props ),
      ( _value, key ) => key.replace( /^_/u, '' ),
    );
    return data;
  }
}
