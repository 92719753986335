import jwt_decode from 'jwt-decode';

export class JWT {

  token: string;

  iat: number;
  exp: number;
  userId?: string;
  impersonatorId?: string;
  error?: string | ( Error & { code?: number; } );

  constructor( token: string ) {
    this.token = token;
    try {
      Object.assign( this, jwt_decode( token ) );
    } catch ( err ) {
      Object.assign( this, { error : err.message } );
    }
  }
  get issued() { return new Date( this.iat * 1000 ); }
  get expires() { return new Date( this.exp * 1000 ); }
  get expired() { return Date.now() > ( this.exp * 1000 ); }

  get authenticated() { return !! this.userId; }
  get impersonating() { return !! this.impersonatorId; }

  get valid() {
    return this.authenticated && ! this.expired && ! this.error;
  }

  fatalize() {
    const err = this.errorize;
    if ( err ) throw err;
  }

  errorize() {
    if ( typeof this.error === 'string' ) return new Error( this.error );
    return this.error;
  }

}
