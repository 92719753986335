import { mkdebug } from '../mkdebug';

export type Deferred<T=unknown> =
  | ( () => Promise<T> )
  | ( ( val: T|Error ) => void );

/**
 * A `deferred` is a function that will either return a promise (if
 * called with no arguments) or resolve the promises that have been
 * returned (if called with an argument that is not an Error
 * instance), or reject those promises (if called with an argument
 * that is an Error instance).
 *
 * @param {string} [label] - An optional label, will be used in
 * debug messages if provided.
 * @example
 * start() {
 *   if ( this._starter ) return this._starter();
 *   this._starter = deferred();
 *   // Do some stuff, possibly in other methods, one of those may
 *   eventually do this:
 *   this._starter( { success : true } );
 *   // or this, to fail
 *   this._starter( new Error( 'failed :(' ) );
 * }
 */
export function deferred( label?: string ): Deferred {
  let resolve, reject, promise;

  const dbg = mkdebug( 'ssp:utils:deferred' );
  const debug = ( x, ...a ) => (
    label ? dbg( label, x, ...a ) : dbg( x, ...a )
  );
  // eslint-disable-next-line @typescript-eslint/no-shadow
  return function deferred( ...args ) {
    if ( ! promise ) {
      debug( 'Creating promise' );
      // eslint-disable-next-line promise/param-names
      promise = new Promise( ( _resolve, _reject ) => {
        resolve = _resolve;
        reject = _reject;
      } );
    }
    if ( args.length === 0 ) {
      debug( 'No arguments, returning promise' );
      return promise;
    } else if ( args.length === 1 ) {
      const [ arg ] = args;
      if ( arg instanceof Error ) {
        debug( 'Called with Error, rejecting', arg );
        reject( arg );
      } else {
        debug( 'Called with non-error, resolving', arg );
        resolve( arg );
      }
    } else {
      throw new Error( `deferred function takes only 0 or 1 arguments` );
    }
  };
}
