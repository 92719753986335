import type { StackItem } from './StackItem';

export function get_link( item: StackItem ) {
  let loc = item.file;
  if ( typeof item.line === 'number' ) {
    loc += ':' + item.line;
    if ( typeof item.column === 'number' ) loc += ':' + item.column;
  }
  if ( BUILD.isClient ) return loc;
  if ( BUILD.isServer ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require( '../server-only/terminal' ).code_link(
      item.mappedFile || item.originalFile || item.file,
      loc, item.line, item.column,
    );
  }
}

let root: string = '';
export function get_root() {
  if ( root ) return root;
  if ( BUILD.isClient ) return root = window.location.origin;
  if ( BUILD.isServer ) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { execFileSync } = require( 'child_process' );
      return root = execFileSync( 'git', [ 'rev-parse', '--show-toplevel' ], {
        encoding : 'utf8',
      } ).trim();
    } catch ( error ) {
      // eslint-disable-next-line no-console
      console.warn( `Unable to find git root: ${error}` );
      return root = process.cwd();
    }
  }
}
