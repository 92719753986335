import _ from 'lodash';
import pMap from 'p-map';

export type Mapper<I extends string|number = string|number, T=any, R=unknown>
  = ( item: T, index: I ) => PromiseLike<R>|R;

/*
export async function mapseries<T=any, R=unknown>(
  iterable: Iterable<PromiseLike<T> | T>,
  mapper: Mapper<number, T, R>,
): Promise<R[]>;
export async function mapseries<T=any, R=unknown>(
  object: Record<string, PromiseLike<T> | T>,
  mapper: Mapper<string, T, R>,
): Promise<R[]>;
*/
export async function mapseries<T=any, R=unknown>(
  iterable: Iterable<PromiseLike<T> | T> | Record<string, PromiseLike<T> | T>,
  mapper: Mapper<string|number, T, R>,
) {
  if ( _.isNil( iterable ) ) return [];
  const result: R[] = [];
  if ( Array.isArray( iterable ) ) {
    let index = 0;

    for ( const value of iterable ) {
      result.push( await mapper( await value, index++ ) );
    }
  } else if ( _.isPlainObject( iterable ) ) {
    for ( const [ key, value ] of Object.entries( iterable ) ) {
      result.push( await mapper( await value, key ) );
    }
  }

  return result;
}

export interface MapOptions {
  readonly concurrency?: number;
  readonly stopOnError?: boolean;
}

/* TODO
export async function map<T=any, R=unknown>(
  iterable: Iterable<PromiseLike<T> | T>,
  mapper: Mapper<number, T, R>,
  opts?: MapOptions,
): Promise<R[]>;
export async function map<T=any, R=unknown>(
  object: Record<string, PromiseLike<T> | T>,
  mapper: Mapper<string, T, R>,
  opts?: MapOptions,
): Promise<R[]>;
*/
export async function map<T=any, R=unknown>(
  iterable: Iterable<PromiseLike<T> | T>,
  mapper: Mapper<string|number, T, R>,
  opts?: MapOptions,
): Promise<R[]> {
  return pMap( Object.entries( iterable ), async ( [ key, value ] ) => {
    return mapper( value, key );
  }, opts );
}
