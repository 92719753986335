import { SSPError } from '../SSPError';

export type WorkerErrorData = {
  /** Worker instance ID. */
  worker?: string;
};
export type WorkerErrorTags = {
  /** Worker service name. */
  service?: string;
};
export interface WorkerErrorOpts {
  /** Worker instance. */
  worker?: $TSFixMe;
}

export class WorkerError<
  Data extends WorkerErrorData = WorkerErrorData,
  Tags extends WorkerErrorTags = WorkerErrorTags,
  Opts extends WorkerErrorOpts = WorkerErrorOpts,
> extends SSPError<Data, Tags, Opts> {
  static readonly status = 'worker-error';
  static readonly message = 'Worker Error';

}

export class WorkerContextError extends WorkerError {}

export type WorkerTaskErrorTags = WorkerErrorTags & {
  /** Task name. */
  task: string;
};
export class WorkerTaskError extends WorkerError<
  WorkerErrorData, WorkerTaskErrorTags
> {}

export type WorkerPoolErrorTags = WorkerErrorTags & {
  /** Pool name. */
  pool: string;
};

export class WorkerPoolError extends WorkerError<
  WorkerErrorData, WorkerPoolErrorTags
> {}
