import _ from 'lodash';
import { isThenable } from '../types';
import { mkdebug } from '../mkdebug';

const debug = mkdebug( 'ssp:utils:hooks:handler' );

export interface HandlerOptions {
  from: string;
  name: string;
  handler: ( ...args: any[] ) => any;
}

export class Handler {

  /** The name of the hook. */
  name: string;
  /** The handler that will be called when the hook is executed. */
  handler: ( ...args: any[] ) => any;
  /** Where the hook was defined. */
  from: string;


  constructor( opts: HandlerOptions ) {
    _.assign( this, opts );
    if ( typeof this.handler !== 'function' ) {
      throw new TypeError( `${this}: Invalid tap (no handler)` );
    }
    debug( `${this}: Created` );
  }

  executeSync( name, args ) {
    const res = this.handler( ...args );
    if ( isThenable( res ) ) {
      throw new Error(
        `${this}: Hook handler returned thenable for sync hook ${name}`,
      );
    }
    return res;
  }

  async executeAsync( name, args ) {
    const res = await this.handler( ...args );
    debug( `${this}: executeAsync ${name} ->`, res );
    return res;
  }

  toString() {
    if ( this.from ) return `Hook.Handler(${this.from})`;
    if ( this.name ) return `Hook.Handler(${this.name})`;
    return `Hook.Handler(UNKNOWN)`;
  }
}
