import _ from 'lodash';
import type { AData, UData } from '@ssp/ts';

export function isRecord<T extends AData = UData>( value: any ): value is T {
  return _.isPlainObject( value );
}

/*
function isPlainObject( value ) {
  if ( typeof value !== 'object' ) return false;
  if ( value === null ) return false;
  if ( Object.prototype.toString.call( value ) !== '[object Object]' ) {
    return false;
  }
  if ( Object.getPrototypeOf( value ) === null ) return true;
  let proto = value;
  while ( Object.getPrototypeOf( proto ) !== null ) {
    proto = Object.getPrototypeOf( proto );
  }
  return Object.getPrototypeOf( value ) === proto;
}
*/
