import _ from 'lodash';
import { AnyError } from './AnyError';
import { SSPErrorOptions } from './types';
import * as EXTRACTORS from './extractors';

const extractors = _.filter( EXTRACTORS, _.isFunction );

export type ExtractableErrorProps = {
  message?: string;
} & Partial<SSPErrorOptions>;

export function extractErrorProperties( error: AnyError ): SSPErrorOptions {
  if ( ! error ) return {};
  for ( const ext of extractors ) {
    const data = ext( error );
    if ( data ) return _.omitBy( data, _.isNil );
  }
  const code = error.statusCode || error.code;
  const status = error.status;
  const res: ExtractableErrorProps = { message : error.message };
  if ( _.isNumber( code ) ) {
    res.code = code;
  } else if ( _.isString( code ) ) {
    res.status = code;
  } else if ( _.isString( status ) ) {
    res.status = status;
  }
  return _.omitBy( res, _.isNil );
}
