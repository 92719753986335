import en from 'nanoid-good/locale/en';
import nanoid_good from 'nanoid-good';

const customAlphabet = nanoid_good.customAlphabet( en );

const alphabet = [
  '0123456789',
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  'abcdefghijklmnopqrstuvwxyz',
].join( '' );
const defaultGen = customAlphabet( alphabet, 16 );

/**
 * Generate a suitable database ID value.  All DB ID generation should
 * be done with this function, which allows us to replace it with
 * a better option later.
 *
 * @param {number} [length=16] - Specify the length of the ID.  IDs
 * for database records should use the default length, this is
 * primarily provided for other use cases.  Note that longer is not
 * necessarily any better.  If generating 16-character IDs at a rate
 * of 1,000 ids per hour you will have a 1% probability of collision
 * after roughly 4 million years.  Even at a rate of 1 million ids per
 * hour it would still take around 1,000 years to reach a 1%
 * probability of collision.
 */
export function idgen( length: number = 16 ): string {
  if ( typeof length !== 'number' ) length = 16;
  if ( length < 8 ) length = 8;
  if ( length > 32 ) length = 32;
  if ( length === 16 ) return defaultGen();
  return customAlphabet( alphabet, length )();
}
