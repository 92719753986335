import _ from 'lodash';

import { addJsonType } from '../json/registry';
import { log } from '../log';

import { SSPError } from './SSPError';
import { getKinds } from './utils';

import type { AnyError } from './AnyError';

export type ErrorConstructor = new ( ...args: any[] ) => Error;

const errorRegistry: Record<string, ErrorConstructor> = {};

[
  // https://262.ecma-international.org/12.0/#sec-well-known-intrinsic-objects
  globalThis.EvalError,
  globalThis.RangeError,
  globalThis.ReferenceError,
  globalThis.SyntaxError,
  globalThis.TypeError,
  globalThis.URIError,

  // https://developer.mozilla.org/en-US/docs/Web/API/DOMException
  globalThis.DOMException,

  // https://nodejs.org/api/errors.html
  globalThis.AssertionError,
  globalThis.SystemError,

  // The default mother-of-all-errors
  globalThis.Error,
].filter( x => x ).forEach( addErrorType );

addJsonType( {
  key    : 'Error',
  encode : encodeErrorType,
  decode : decodeErrorType,
} );

const selectorRegistry: Record<string, string> = {};

export function getErrorType( key: string ) {
  return errorRegistry[ key ];
}

export function addErrorSelectors(
  input : Record<string, string | number | string[]>,
) {
  for ( const [ error, selectors ] of Object.entries( input ) ) {
    for ( const sel of _.map( _.castArray( selectors ), String ) ) {
      const had = selectorRegistry[ sel ];
      if ( had ) {
        throw new Error(
          `Error selector registry already has '${sel}' assigned to '${had}'`,
        );
      }
      selectorRegistry[ sel ] = error;
    }
  }
}

export function addErrorType( error: ErrorConstructor ): void {
  if ( errorRegistry[ error.name ] ) {
    throw new Error(
      `Error type registry already has a type named "${error.name}"`,
    );
  }
  errorRegistry[ error.name ] = error;
}
export function findErrorType( ...names_or_selectors: ( string | number )[] ) {
  for ( const name of names_or_selectors ) {
    if ( selectorRegistry[ name ] ) {
      const found = getErrorType( selectorRegistry[ name ] );
      if ( found ) return found;
    }
    if ( errorRegistry[ name ] ) return errorRegistry[ name ];
  }
}

function findErrorKind( kinds: string[] ) {
  for ( const kind of kinds ) {
    if ( typeof kind !== 'string' ) continue;
    if ( errorRegistry[ kind ] ) {
      return errorRegistry[ kind ];
    } else {
      log.debug( `Unknown error type: ${kind}` );
    }
  }
  return Error;
}

export function encodeErrorType( error: AnyError ) {
  if ( ! ( error instanceof Error ) ) return;
  const kinds = getKinds( error );
  const data = ( error instanceof SSPError )
    ? error.getSerializableData()
    : _.pick( error, [
      'name', 'message', 'code', 'statusCode', 'stack', 'index',
    ] );

  const props = _.omitBy( data, ( val, key ) => {
    if ( ! _.isString( key ) ) return true;
    if ( key === '$kinds' ) return true;
    if ( _.isNil( val ) ) return true;
    if ( _.isArray( val ) || _.isPlainObject( val ) ) {
      return _.isEmpty( val );
    }
  } );
  return { $kinds : kinds, ...props };
}

export function decodeErrorType( input ) {
  const { $kinds = [ input.name ], ...data } = input;
  const Class = findErrorKind( $kinds );
  const error = Object.create( Class.prototype, _.mapValues( data, value => ( {
    enumerable    : true,
    configurable  : false,
    writable      : true,
    value,
  } ) ) );
  if ( typeof error.rebuild === 'function' ) error.rebuild();
  return error;
}
