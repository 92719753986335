/**
 * This module provides a simple wrapper for things in the
 * `@ssp/utils` library to use for logging.  It will use `@ssp/logger`
 * if it's been loaded, and fallback to using console log methods if
 * it hasn't.  This is because we want to be able to log from utils
 * functions sometimes, but we can't depend on `@ssp/logger` because
 * it depends on `@ssp/utils`.
 */
export interface Log {
  trace( ...args: any[] ): void;
  debug( ...args: any[] ): void;
  info( ...args: any[] ): void;
  warn( ...args: any[] ): void;
  error( ...args: any[] ): void;
  fatal( ...args: any[] ): void;
}

export const log: Log = {
  trace( ...args ) { getLogger().trace( ...args ); },
  debug( ...args ) { getLogger().debug( ...args ); },
  info( ...args ) { getLogger().info( ...args ); },
  warn( ...args ) { getLogger().warn( ...args ); },
  error( ...args ) { getLogger().error( ...args ); },
  fatal( ...args ) { getLogger().fatal( ...args ); },
};

/* eslint-disable no-console */
const fallback_logger: Log = {
  trace( ...args ) { console.warn( ...args ); },
  debug( ...args ) { console.warn( ...args ); },
  info( ...args ) { console.warn( ...args ); },
  warn( ...args ) { console.warn( ...args ); },
  error( ...args ) { console.error( ...args ); },
  fatal( ...args ) { console.error( ...args ); },
};

export function getLogger() {
  // @ts-ignore
  if ( typeof ctx === 'undefined' ) {
    return fallback_logger;
  } else {
    // @ts-ignore
    return ctx?.logger || fallback_logger;
  }
}
