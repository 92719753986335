import _ from 'lodash';

/**
 * Recursively freeze an object, making it unmodifiable.
 *
 * @param obj - The object to freeze.
 */
export function deepFreeze( obj: any ) {
  Object.freeze( obj );

  Object.getOwnPropertyNames( obj ).forEach( prop => {
    if ( ! obj.hasOwnProperty( prop ) ) return;
    const val = obj[ prop ];
    if ( _.isNil( val ) ) return;
    const type = typeof val;
    if ( type !== 'object' && type !== 'function' ) return;
    if ( Object.isFrozen( val ) ) return;
    deepFreeze( val );
  } );

  return obj;
}
