import _ from 'lodash';

import { SSPError, SimplifiedError } from '../SSPError';

import type { ErrorData, ErrorTags, ErrorOpts } from '../types';

export type ValidationErrorData = ErrorData & {
  /** Validation Context */
  context?: any;
  /** Schema name. */
  schema: string;
  /** Parameter or field name. */
  param?: string;
  field?: string;
  /** Parameter or field type. */
  type?: string;
  /** Validator name. */
  validator?: string;
  /** Value */
  value?: any;
};
export type ValidationErrorTags = ErrorTags & {
  /** Schema name. */
  schema: string;
};
export type ValidationErrorOpts = ErrorOpts;

/** This is an error class for a single validation error. */
export class ValidationError extends SSPError<
  ValidationErrorData, ValidationErrorTags, ValidationErrorOpts
> {
  static readonly status = 'validation-error';
  static readonly message = 'Validation Error';

  ctxval( ...keys: string[] ) {
    for ( const key of keys ) {
      const val = this.data[ key ] ?? this.data.context?.[ key ];
      if ( ! _.isNil( val ) ) return val;
    }
  }
  get schema() { return this.ctxval( 'schema' ); }
  get param() { return this.ctxval( 'param', 'field' ); }
  get field() { return this.ctxval( 'field', 'param' ); }
  get type() { return this.ctxval( 'type' ); }
  get value() { return this.ctxval( 'value' ); }
}

export type ValidationErrorsData = ErrorData & {
  /** Validation Context */
  context?: any;
  /** Schema name. */
  schema: string;
  /**
   * Parameter or field name. This gets set on a ValidationErrors
   * instance when all the child errors are related to the same field,
   * such as in the case of validating an array field.
   */
  param?: string;
  field?: string;
};
export type ValidationErrorsTags = ErrorTags & {
  /** Schema name. */
  schema: string;
};
export type ValidationErrorsOpts = ErrorOpts;

/**
 * This class encapsulates multiple ValidationError objects into one
 * combined collection of errors.  It is used both when performing
 * multiple validations (such as when validating all the params in
 * a schema without using bail) or in some cases for aggregate
 * validator types (such as 'and', 'or', 'every' ).
 */
export class ValidationErrors extends SSPError<
  ValidationErrorsData, ValidationErrorsTags, ValidationErrorOpts
> {
  static readonly status = 'validation-errors';
  static readonly message = 'Validation Errors';

  simplify() {
    if ( this.data.context?.array ) {
      return this.errors.map( err => SSPError.simplify( err ) );
    } else {
      const res: Record<string, SimplifiedError> = {};
      for ( const err of this.errors ) {
        const key = err.param ?? err.field ?? err.index;
        if ( key ) res[ key ] = SSPError.simplify( err );
      }
      return res;
    }
  }

}
