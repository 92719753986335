import _ from 'lodash';
import { mkdebug } from '../mkdebug';
import { log } from '~/log';

const debug = mkdebug( 'ssp:utils:promise:background' );

export interface BackgroundOptions {
  /** Label, for logging purposes. */
  label?: string;
  /** Tags, for logging purposes. */
  tags?: Record<string, string|number|boolean>;
  /**
   * Callback to call when the promise is rejected.
   */
  onReject?: ( err: Error ) => any;
  /**
   * Callback to call when the promise is resolved.
   */
  onResolve?: ( err: Error ) => any;
  /**
   * Whether or not to log rejections.  If a string, will be logged at
   * that loglevel.  If `true` will be logged as an `error`.  If
   * `false` or `undefined` will not be logged.
   */
  logReject?: boolean|'error'|'info'|'debug'|'trace';
  /**
   * Whether or not to log resolutions.  If a string, will be logged at
   * that loglevel.  If `true` will be logged as an `error`.  If
   * `false` or `undefined` will not be logged.
   */
  logResolve?: boolean|'error'|'info'|'debug'|'trace';
}
/**
 * Wrap a background task to ensure that it has at least an error
 * handler and to make it clear that it should be happening in the
 * background when we abandon promises.
 *
 * @param {Function|Promise} task - A promise or
 * a promise-returning function.
 */
export function background<T=unknown>(
  task: PromiseLike<T> | ( ( ...args: any[] ) => PromiseLike<T> ),
  opts: BackgroundOptions = {},
) {
  _.defaults( opts, {
    logReject   : 'error',
    logResolve  : false,
  } );
  debug( 'Backgrounding task: %s', task );
  if ( opts.logReject === true ) opts.logReject = 'error';
  if ( opts.logResolve === true ) opts.logResolve = 'debug';
  const msg: any = [ 'background task' ];
  if ( opts.label ) msg.push( `"${opts.label}"` );
  if ( opts.tags ) msg.unshift( opts.tags );
  return new Promise( ( resolve, reject ) => {
    if ( _.isFunction( task ) ) {
      try {
        task = task();
      } catch ( err ) {
        return reject( err );
      }
    }
    resolve( task );
  } ).then( res => {
    debug( 'Background Task %s resolved: %o', opts.label, res );
    if ( opts.logResolve ) {
      const level = opts.logResolve === true ? 'debug' : opts.logResolve;
      msg.push( 'was resolved' );
      log[ level ]( ...msg );
    }
    if ( _.isFunction( opts.onResolve ) ) return opts.onResolve( res );
    return res;
  } ).catch( err => {
    debug( 'Background Task %s rejected: %o', opts.label, err );
    if ( opts.logReject ) {
      const level = opts.logReject === true ? 'debug' : opts.logReject;
      msg.push( 'was rejected', err );
      log[ level ]( ...msg );
    }
    if ( _.isFunction( opts.onReject ) ) return opts.onReject( err );
    return err;
  } );
}
