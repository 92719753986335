import { SSPError } from './errors';

const prefix: string = 'Invariant failed';

export class InvariantError extends SSPError {
  thrower = invariant;
}

/**
 * Throw an error if the condition fails
 *
 * @param message - A message string, or a function that will produce
 * a string.  Note that the message will be removed in production.
 */
export function invariant(
  condition: any,
  message?: string | ( () => string ),
): asserts condition {
  if ( condition ) return;

  if ( BUILD.isProd ) throw new InvariantError( prefix );

  const msg: string|undefined = typeof message === 'function'
    ? message() : message;
  const err = new InvariantError( msg ? `${prefix}: ${msg}` : prefix );
  throw err;
}
