
/**
 * Wrap a promise-returning function in a way that ensures that only
 * one instance of it can be in flight at a time.  If it attempts to
 * run again while it's already running, then another instance of
 * a promise that depends on the same in-flight promise will be
 * returned.
 *
 * @param {Function} fn - A promise-returning function.
 */
export function throttle( fn: () => any ) {
  if ( typeof fn !== 'function' ) {
    throw new Error( `throttle requires a function` );
  }
  let promise;

  return function throttled() {
    if ( arguments.length ) {
      // eslint-disable-next-line no-console
      console.warn( 'throttle function ignores passed arguments' );
    }
    if ( ! promise ) {
      promise = Promise.resolve( fn() )
        .finally( () => { promise = undefined; } );
    }
    return promise;
  };
}

/**
 * Create a throttle function that takes an argument.
 * Each value will be throttled separately.
 *
 * @param {Function} fn - A promise-returning function that takes
 * a single argument.
 * @param {Function} [mapper] - A (synchronous) function that takes
 * the arguments passed to the throttled function and returns
 * a suitable cache key.  Note that if you don't provide a mapper then
 * the first (and only) argument will be used as the key and will also
 * be passed through to the function.  In this case the function will
 * throw if passed more than one argument.
 */
export function throttleMap( fn, mapper ) {
  if ( typeof fn !== 'function' ) {
    throw new Error( `throttleMap requires a function` );
  }
  if ( ! mapper ) {
    mapper = ( ...args ) => {
      if ( args.length === 1 ) return args[0];
      throw new Error(
        `throttled function without mapper must take exactly 1 argument`,
      );
    };
  }
  if ( typeof mapper !== 'function' ) {
    throw new Error( `throttleMap mapper must be a function` );
  }
  const map = new Map();

  return function throttled( ...args ) {
    const key = mapper( ...args );
    if ( ! map.has( key ) ) {
      const promise = Promise.resolve( fn( ...args ) )
        .finally( () => map.delete( key ) );
      map.set( key, promise );
    }
    return map.get( key );
  };
}
