
/**
 * A linked-list link object, used for maintaining the
 * least-recently-used list of the BoundedMap.
 */
export interface Link<T> {
  /** The key this value is registered under in the map. */
  key: string;
  /** The stored value. */
  value: T;
  /** The next link in the list. */
  next?: Link<T>;
  /** The previous link in the list. */
  prev?: Link<T>;
}

export class BoundedMap<T> extends Map<string, Link<T>> {
  /** The head of the map. */
  head?: Link<T> = null;
  /** The tail of the map. */
  tail?: Link<T> = null;
  /**
   * Soft size limit.  When the soft limit is reached and a new entry
   * is added to the map, evictable entries will be removed from the
   * map until the size is back under the soft limit.  If there are no
   * evictable entries then the map may continue to grow until it
   * reaches the hard limit.
   */
  soft: number = 10000;
  /**
   * Hard size limit.  When the hard size limit is reached and a new
   * entry is added to the map, the least-recently-used entry from the
   * end of the map is removed to get back under the hard limit.  The
   * hard limit removes entries whether they are considered evictable
   * or not.
   */
  hard: number = Infinity;

  /**
   * @param opts.evictable - Evictable function.  This is a function
   * that takes a value and returns true or false to indicate if that
   * value should be considered evictable.  This allows for things
   * like TTL to be easily supported.  If no function is provided for
   * this, then the soft limit has no effect and only the hard limit
   * will control the size of the map.
   */
  declare evictable?: ( item: T ) => boolean;

  constructor( opts: Partial<Pick<BoundedMap<T>, 'soft'|'hard'|'evictable'>> ) {
    super();
    if ( typeof opts.soft === 'number' ) this.soft = opts.soft;
    if ( typeof opts.hard === 'number' ) this.hard = opts.hard;
    if ( typeof opts.evictable === 'function' ) this.evictable = opts.evictable;
  }

  /**
   * Unlink a linked-list entry, splicing it out of the links,
   * probably in preparation for inserting it somewhere else.
   *
   * @param link - Linked-list entry.
   */
  unlink( link: Link<T> ): void {
    if ( this.head === link ) this.head = link.next;
    if ( this.tail === link ) this.tail = link.prev;
    if ( link.next ) link.next.prev = link.prev;
    if ( link.prev ) link.prev.next = link.next;
  }

  /**
   * Return a stored value, moving that entry to the front of the
   * list (indicating it is the most recently used entry).
   *
   * @param key - The key to retrieve from the map.
   */
  // @ts-ignore
  get( key: string ): T|undefined {
    const link = super.get( key );
    if ( ! link ) return;
    this.promote( link );
    return link.value;
  }

  /**
   * Return a stored value, but without moving it to the front of the
   * list.  This allows the value to be used for non-interactive
   * things (like background updating) without having the fact that it
   * was updated change it's position in the list.
   *
   * @param key - The key to retrieve from the map.
   */
  peek( key: string ): T|undefined {
    const link = super.get( key ) as unknown as Link<T>;
    if ( ! link ) return;
    return link.value;
  }

  /**
   * Set a stored value in the map.  This adds the value to the front
   * of the list, making it the most recently used value.
   *
   * @param key - The key to set in the map.
   * @param value - The value to store.
   */
  // @ts-ignore
  set( key: string, value: T ): T {
    super.delete( key );
    const link = { key, value };
    super.set( key, link as any );
    this.promote( link );
    return value;
  }

  /** Clear the map, removing all entries. */
  clear(): void {
    super.clear();
    this.head = null;
    this.tail = null;
  }

  /**
   * Delete an entry from the map.
   *
   * @param {string|link} link - The link to delete from the list.  If
   * given a string, will retrieve the link with that key and delete
   * that.
   */
  // @ts-ignore
  delete( link: Link<T> ): void {
    if ( typeof link === 'string' ) link = super.get( link );
    if ( ! link ) return;
    this.unlink( link );
    super.delete( link.key );
  }

  /**
   * Move the entry to the most-recently-used position.
   *
   * @param link - The link to promote.  If given a string will
   * retrieve the link with that key and promote that.
   */
  promote( link: Link<T> ): void {
    if ( typeof link === 'string' ) link = super.get( link );
    if ( link === this.head ) return;
    this.unlink( link );
    link.prev = null;
    link.next = this.head;
    if ( this.head ) this.head.prev = link;
    this.head = link;
    if ( ! this.tail ) this.tail = link;
  }

  /**
   * Run the eviction algorithm, removing evictable entries until the
   * size of the list is under the soft limit, and removing tail-end
   * values until the size is under the hard limit.
   */
  evict(): void {
    if ( this.size < this.soft ) return;
    // If we have an `evictable` function, start by removing entries
    // that it returns true for..
    if ( typeof this.evictable === 'function' ) {
      // start at the end of the list
      let link = this.tail;
      // We remove evictable entries to reach the soft limit
      while ( link && this.size > this.soft ) {
        if ( this.evictable( link.value ) ) this.delete( link );
        link = link.prev;
      }
    }
    // If we're over the hard limit, remove LRU entries until we get
    // back under it.
    while ( this.size > this.hard ) this.delete( this.tail );
  }

  * keys(): IterableIterator<string> {
    let link = this.head;
    while ( link ) {
      yield link.key;
      link = link.next;
    }
  }

  // @ts-ignore - stored link not directly returned
  * entries(): IterableIterator<[string, T]> {
    let link = this.head;
    while ( link ) {
      yield [ link.key, link.value ];
      link = link.next;
    }
  }

  // @ts-ignore - stored link not directly returned
  * values(): IterableIterator<T> {
    let link = this.head;
    while ( link ) {
      yield link.value;
      link = link.next;
    }
  }

  // @ts-ignore - stored link not directly returned
  [ Symbol.iterator ](): IterableIterator<[string, T]> {
    return this.entries();
  }
}
