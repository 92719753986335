import _ from 'lodash';
import { BadRequest } from './errors';

import type { KeysWithType, AData, UData } from '@ssp/ts';

export class InvalidSelector extends BadRequest {
  message = 'Invalid selector';
  required_data = [ 'selector' ];
}

// Names of objects (or union of other properties)
export type SelectorNames<
  T extends AData, D extends Readonly<T[]>, K extends keyof T
> = D[number][K];

// Flags (boolean properties) of objects
export type SelectorFlags<T extends AData> = KeysWithType<T, boolean> & string;

export type AddSelector<
  T extends AData, D extends Readonly<T[]>, K extends keyof T
> = SelectorNames<T, D, K> | SelectorFlags<T>;
export type DelSelector<
  T extends AData, D extends Readonly<T[]>, K extends keyof T
> = `-${SelectorNames<T, D, K>}` | `-${SelectorFlags<T>}`;
export type BaseSelector<
  T extends AData, D extends Readonly<T[]>, K extends keyof T
> = AddSelector<T, D, K> | DelSelector<T, D, K>

export type SelectorsFor<
  T extends AData, D extends Readonly<T[]>, K extends keyof T
> =
  | BaseSelector<T, D, K>
  | ( ( item: T ) => boolean )
  | SelectorsFor<T, D, K>[];

export type Filter<T extends AData = UData> = ( item: T ) => boolean;
export interface SelectObjectsOptions<
  T extends AData, D extends Readonly<T[]>, K extends keyof T
> {
  selectors: SelectorsFor<T, D, K>;
  objects: D;
  names: K[];
  transform?: ( selector: BaseSelector<T, D, K> ) => Filter | undefined;
}

export function selectObjects<
  T extends AData,
  D extends Readonly<T[]>,
  K extends keyof T & string
>( options: SelectObjectsOptions<T, D, K> ): T[] {
  const { selectors, objects, transform } = options;
  const names = Array.isArray( options.names )
    ? options.names : [ options.names ];
  const res: Set<T> = new Set();
  const find = ( filter: Filter, invert: boolean = false ) => {
    if ( invert ) {
      return objects.filter( o => ! filter( o ) );
    } else {
      return objects.filter( filter );
    }
  };
  const each = (
    filter: Filter, negate: boolean = false, invert: boolean = false,
  ) => {
    const method = negate ? 'delete' : 'add';
    find( filter, invert ).forEach( o => res[ method ]( o ) );
  };
  const make_filter = ( selector ) => {
    if ( transform ) {
      const filter = transform( selector );
      if ( typeof filter === 'function' ) return filter;
    }
    if ( selector === '*' || selector === '@all' ) {
      // You can specify `fields : [ '*' ]` or `fields : [ '@all' ]`
      return () => true;
    }
    if ( /^[\w.]+$/u.test( selector ) ) {
      // By name properties
      return obj => names.some( prop => _.get( obj, prop ) === selector );
    }
    if ( selector.startsWith( '@has:' ) ) {
      // You can specify by fields that have a defined value for
      // a given property:
      // `fields : [ '@has:default' ]`
      const name = selector.slice( 5 );
      const methodName = `has${_.startCase( selector )}`;
      return field => {
        if ( _.isFunction( field[ methodName ] ) ) return field[ methodName ]();
        return ! _.isNil( field[ name ] );
      };
    }
    const x = /^@([\w.]+):(.+)$/u.exec( selector );
    if ( x ) {
      // You can filter by any property in a similar manner to @type:
      const [ key, value ] = x.slice( 1 );
      return obj => String( obj[ key ] ) === value;
    }
    if ( selector.startsWith( '@' ) ) {
      // You can also specify groups of fields with:
      // `fields : [ '@summary', '@unique', '@quicksearch', '@minimal' ]`,
      // or, for example, leave out all the metadata fields:
      // `fields : [ '*', '-@metadata' ]`,
      selector = selector.slice( 1 );
      return obj => Boolean( obj[ selector ] );
    }
  };
  const proc = ( selector ) => {
    if ( ! selector ) return;
    if ( _.isArray( selector ) ) return selector.map( proc );
    if ( _.isPlainObject( selector ) ) return each( _.iteratee( selector ) );
    if ( ! _.isString( selector ) ) {
      throw new InvalidSelector( `Invalid selector "${selector}"`, {
        selector,
      } );
    }
    const negate = selector.startsWith( '-' );
    if ( negate ) selector = selector.slice( 1 );
    const invert = selector.startsWith( '!' );
    if ( invert ) selector = selector.slice( 1 );
    const filter = make_filter( selector );
    if ( typeof filter !== 'function' ) {
      throw new InvalidSelector( `Invalid selector "${selector}"`, {
        selector,
      } );
    }
    each( filter, negate, invert );
  };
  proc( selectors );
  return Array.from( res );
}
