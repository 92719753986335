import { SSPError } from '../SSPError';
import { TemporaryError } from '../abstracts';

import type { ErrorData, ErrorTags, ErrorOpts } from '../types';

export class ConfigError<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly message = 'Configuration Error';
}

export class BadRequest<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 400;
}

export class NotAuthenticated<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 401;
}
export class PaymentError<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 402;
}
export class Forbidden<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 403;
  static readonly status = 'forbidden';
  static readonly message = 'Permission denied';
}
export class MethodNotAllowed<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 405;
}
export class NotAcceptable<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 406;
}
export class Conflict<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 409;
}
export class Gone<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 410;
}
export class LengthRequired<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 411;
}
export class Unprocessable<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 422;
}
export class GeneralError<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 500;
}
export class NotImplemented<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 501;
}
export class BadGateway<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 502;
}
export class Unavailable<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 503;
}

export class NotFound<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly code = 404;
  static readonly status = 'not-found';
  static readonly message = 'Resource Not Found';
  is_not_found = true;
}

export class Cancelled<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
  static readonly message = 'Cancelled';
  is_cancelled = true;
}

export class Timeout<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends TemporaryError<Data, Tags, Opts> {
  static readonly code = 408;
  static readonly status = 'timeout';

  static readonly message = 'Timeout Exceeded';
  is_timeout = true;
}

export class TooManyRequests extends TemporaryError {
  static readonly code = 429;
}
export class TemporaryFailure extends TemporaryError {
  static readonly message = 'Temporary Failure';
}

export class Assertion<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
}

export class Deprecated<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Opts> {
}

export type DuplicateKeyData = ErrorData & {
  value: any;
};
export type DuplicateKeyTags = ErrorTags & {
  field: string;
};
export class DuplicateKey<
  Data extends DuplicateKeyData = DuplicateKeyData,
  Tags extends DuplicateKeyTags = DuplicateKeyTags,
  Opts extends ErrorOpts = ErrorOpts
> extends Conflict<Data, Tags, Opts> {
  static readonly code = 409;
  static readonly status = 'duplicate-key';

  is_unique_key_conflict = true;

  initialize() {
    if ( this._message ) return;
    const value = this.data.value;
    const field = this.tags.field;
    if ( value && field ) {
      this._message = `"${value}" is taken, please provide a new ${field}`;
    } else if ( value ) {
      this._message = `"${value}" is taken`;
    }
  }
}

export class UnknownError<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends GeneralError<Data, Tags, Opts> {
  static readonly message = 'Unknown Error';

  is_unknown = true;
}
