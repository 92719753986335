import { timeout } from './timeout';
import { delay } from './delay';
import type { Merge } from '@ssp/ts';

export interface WaitForOptions {
  /** How long to wait before checking again. @default '1s' */
  readonly delay?: string;

  /**
   * How long to retry before giving up and rejecting with a `TimeoutError`.
   *
   * @default Infinity
   */
  readonly timeout?: string|number;

  /**
   * If true will run the check immediately.  if false will start by
   * waiting for the delay.  Useful in the case where there is
   * little chance the condition will succeed on the first attempt.
   */
  readonly immediate?: boolean;
}
export type WaitForFunction<T=unknown> = () => PromiseLike<T|null>;

/**
 * Wait for a condition to be true.
 */
/* TODO - Not sure what is wrong with this overload...
export function waitfor<T=unknown>(
  condition: WaitForFunction<T>,
  options: Merge<WaitForOptions, { timeout: string }>,
): CancelablePromise<T>;
*/
export function waitfor<T=unknown>(
  condition: WaitForFunction<T>,
  options: Merge<WaitForOptions, { timeout: never }>,
): Promise<T>;
export function waitfor<T=unknown>(
  condition: WaitForFunction<T>
): Promise<T>;
export function waitfor<T=unknown>(
  condition: WaitForFunction<T>,
  options: WaitForOptions = {},
) {
  const {
    delay : delay_spec = '1s',
    timeout : timeout_spec,
    immediate = true,
  } = options;

  let delayed;

  const promise = new Promise( ( resolve, reject ) => {
    const check = async () => {
      try {
        const value = await condition();

        if ( value === null ) {
          delayed = delay( delay_spec );
          await delayed;
          check();
        } else {
          resolve( value );
        }
      } catch ( error ) {
        reject( error );
      }
    };

    if ( immediate ) {
      check();
    } else {
      delayed = delay( delay_spec );
      delayed.then( check );
    }
  } );

  if ( timeout_spec ) {
    return timeout( timeout_spec, promise ).catch( error => {
      if ( delayed ) delayed.cancel();
      throw error;
    } );
  } else {
    return promise;
  }
}
