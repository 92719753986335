import { isObject } from '.';

import type { Constructor } from '@ssp/ts';

export type Primitive =
  | string
  | number
  | boolean
  | symbol
  | undefined
  | null;

/**
 * Check whether the argument is a primitive (not an object).
 *
 * @param value - value to test.
 * @returns Boolean.
 */
export function isPrimitive( value: any ): value is Primitive {
  if ( value === null ) return true;
  switch ( typeof value ) {
    case 'boolean':
    case 'number':
    case 'string':
    case 'symbol':
    case 'undefined':
      return true;
    default:
      return false;
  }
}

/**
 * Check whether the argument is a constructor.  Returns true if
 * the passed argument is a function with a prototype.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isConstructor(
  value: any,
): value is Constructor<unknown, unknown[]> {
  return isFunction( value ) && isObject( value.prototype );
}

/**
 * Check if the argument is a constructor, and return true if the
 * object it constructs will inherit from the provided parent.
 *
 * @param {any} value - value to test.
 * @param {Function} parent - The parent to check for inheritance from.
 * @returns Boolean
 */
export function isConstructorOf( value, parent ) {
  return isConstructor( value ) && ( value.prototype instanceof parent );
}

/**
 * Check whether the argument is an instance of a Class.  Returns true
 * if the passed argument is an object with a constructor function.
 *
 * @param {any} value - value to test.
 * @returns Boolean.
 */
export function isInstance( value ) {
  return isObject( value ) && isFunction( value.constructor );
}
export function isInstanceOf( value, parent ) {
  return isConstructor( parent )
    && isInstance( value )
    && ( value.prototype instanceof parent );
}
export function isClass( value ) { return isConstructor( value ); }

/**
 * Check whether the value provided is a thenable.
 *
 * @param {any} value - The value to test.
 */
export function isThenable<T=unknown>( value: any ): value is PromiseLike<T> {
  return isObject( value ) && isFunction( ( value as any ).then );
}
/**
 * Check whether the value provided is a promise.  The difference
 * between this and `isThenable` is that `isThenable` only requires
 * a `.then` method, this also requires `.catch`.
 *
 * @param {any} value - The value to test.
 */
export function isPromise<T=unknown>( value: any ): value is Promise<T> {
  return isThenable( value ) && isFunction( ( value as any ).catch );
}

export function isFunction( value: any ): value is ( ...args: any[] ) => any {
  return typeof value === 'function';
}
