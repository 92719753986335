import _ from 'lodash';

import type { AnyError } from '../AnyError';

/**
 * Unfortunately execa doesn't do anything that would be helpful like
 * throw it's own error types. In some cases it just augments existing
 * NodeJS errors, and in others it just throws a generic `Error`. For
 * both cases we have to identify it by the properties that it adds to
 * them, which are encapsulated in this type.
 */
export type ExecaErrorType = {

  /**
   * Error message. This is the full, long error message including the
   * output that went to `stdout` and `stderr` as well as the stack
   * trace.
   */
  message: string;

  /**
   * In the case where an existing error was wrapped, it will have an
   * `originalMessage` property with the `message` from the error that
   * was wrapped.
   */
  originalMessage?: string;

  /**
   * Short version of the error message without all the added
   * diagnostics. If an error was wrapped this will include the execa
   * error message and the original error message separated by
   * a newline.
   */
  shortMessage: string;

  /** The raw command that was being run. */
  command: string;

  /** The shell-escaped version of the command. */
  escapedCommand: string;

  /** Process exit code (if available). */
  exitCode?: number;

  /** Signal that killed the process (if any). */
  signal?: number;

  /** Human-readable description of the signal. */
  signalDescription?: string;

  /** The command's stdout. */
  stdout?: string;

  /** The command's stderr. */
  stderr?: string;

  /**
   * If execa was called with `all: true` then this will be the merged
   * stdout+stderr output.
   */
  all?: string;

  /** This will always be true for execa errors. */
  failed: true;

  name: string;
  stack: string;
  killed: boolean;
  timedOut: boolean;
  isCanceled: boolean;
};
const checkers: {
  [K in keyof ExecaErrorType]: ( value: any ) => value is ExecaErrorType[K];
} = {
  message           : _.isString,
  shortMessage      : _.isString,
  command           : _.isString,
  escapedCommand    : _.isString,
  failed            : ( x: any ): x is true => x === true,
  name              : _.isString,
  stack             : _.isString,
  killed            : _.isBoolean,
  timedOut          : _.isBoolean,
  isCanceled        : _.isBoolean,
};
const execaResponseKeys: ( keyof ExecaErrorType )[] = [
  'all',
  'command',
  'escapedCommand',
  'exitCode',
  'failed',
  'isCanceled',
  'killed',
  'message',
  'name',
  'shortMessage',
  'stack',
  'stderr',
  'stdout',
  'timedOut',
];

export function extractExecaError( error: AnyError ) {
  if ( ! isExecaError( error ) ) return;
  return {
    message   : error.message,
    _message  : error.shortMessage,
    tags      : {
      library   : 'execa',
    },
  };
}

export function isExecaError( value: any ): value is ExecaErrorType {
  if ( typeof value !== 'object' ) return false;
  const keys = Object.keys( value );
  if ( ! keys.length ) return false;
  return keys.every( key => execaResponseKeys.includes( key as any ) );
}
