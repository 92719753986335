import _ from 'lodash';

export const reducers = {
  replace( _state, payload ) { return payload; },
  merge( state, payload ) { return _.assign( {}, state, payload ); },
  concat( state, payload ) { return [].concat( state ).concat( payload ); },
  squish,
  list( state, payload ) {
    const ret = new Set();
    if ( _.isArray( state ) ) _.each( state, x => ret.add( x ) );
    for ( const arg of _.filter( squish( payload ), _.isString ) ) {
      if ( arg.startsWith( '-' ) ) {
        ret.delete( arg.slice( 1 ) );
      } else if ( arg.startsWith( '+' ) ) {
        ret.add( arg.slice( 1 ) );
      } else if ( arg.startsWith( '!' ) ) {
        if ( ret.has( arg.slice( 1 ) ) ) {
          ret.delete( arg.slice( 1 ) );
        } else {
          ret.add( arg.slice( 1 ) );
        }
      } else {
        ret.add( arg );
      }
    }
    return squish( Array.from( ret ) );
  },
} as const;
export type Reducers = typeof reducers;
export type ReducerName = keyof Reducers;
export type ReducerFn<State=unknown, Input=State>
  = ( state: State|undefined, action: Input ) => State;
export type Reducer<State=unknown, Input=State>
  = ReducerName | ReducerFn<State, Input>;

function squish( ...args ) {
  return _.compact( _.uniq( _.flattenDeep( args ) ) );
}

export function getReducer( arg: Reducer ): ReducerFn {
  if ( typeof arg === 'function' ) return arg;
  if ( reducers[ arg ] ) return reducers[ arg ];
  throw new Error( `Unknown store reduce function "${arg}"` );
}
