import { init } from '../init';
import { scrubSecrets } from './scrubber';


if ( ! registerScrubber() ) {
  init.register( {
    id      : 'log-scrubber',
    after   : '@start',
    before  : '@_start',
    start   : () => { registerScrubber(); },
  } );
}

function registerScrubber(): boolean {
  if ( ! SSP?.events ) return false;
  SSP.events.on( 'log', ( data ) => {
    data.data = scrubSecrets( data.data );
    data.message = scrubSecrets( data.message );
  } );
}
