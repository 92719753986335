import { DateTime, Duration, Interval } from 'luxon';

import { addJsonType } from '../registry';

addJsonType<Date, string>( {
  key  : 'Date',
  encode( value ) {
    if ( value instanceof Date ) return value.toISOString();
  },
  decode( value ) { return new Date( value ); },
} );

addJsonType<DateTime, string>( {
  key  : 'DateTime',
  encode( value ) {
    if ( value instanceof DateTime ) return value.toISO();
  },
  decode( value ) { return DateTime.fromISO( value ); },
} );

addJsonType<Duration, string>( {
  key  : 'Duration',
  encode( value ) {
    if ( value instanceof Duration ) return value.toISO();
  },
  decode( value ) { return Duration.fromISO( value ); },
} );

addJsonType<Interval, string>( {
  key  : 'Interval',
  encode( value ) {
    if ( value instanceof Interval ) return value.toISO();
  },
  decode( value ) { return Interval.fromISO( value ); },
} );
