export function createNamedClass<
  T extends new( ...args: any[] ) => any
>( name: string, parent: T, label?: string ): T {
  // Wrapping the class declaration in an object and then
  // destructuring it is a trick to let you define the name for an
  // anonymous class.  https://stackoverflow.com/a/48813707/3443454
  const cls = { [ name ] : class extends parent {} }[ name ];
  if ( label ) {
    Object.defineProperty( cls, 'toString', { value : () => label } );
  }
  return cls as T;
}
