export interface AnyError extends Error {
  [key: string]: any;
}
export interface AnyCodedError extends AnyError {
  code: number;
}

export function isAnyError( val: any ): val is AnyError {
  return val instanceof Error;
}
export function isAnyCodedError( val: any ): val is AnyCodedError {
  return isAnyError( val ) && typeof val.code === 'number';
}
