import _ from 'lodash';

const defaults = {
  HOST      : 'unknown.example.com',
  ROOT_URL  : () => `http://${env( 'HOST' )}`,
};

const data = BUILD.isClient ? {
  ROOT_URL  : () => window.location.origin,
} : {
  ROOT_URL  : () => process.env.ROOT_URL,
};

type EnvFn = ( key: string, defaultValue?: string|EnvFn ) => string | undefined;
export function env( key: string, defaultValue?: string | EnvFn ) {
  if ( typeof process !== 'undefined' ) {
    if ( ! _.isNil( process.env[ key ] ) ) return process.env[ key ];
  }
  if ( _.isFunction( data[ key ] ) ) return data[ key ]();
  if ( _.isNil( defaultValue ) ) defaultValue = defaults[ key ];
  if ( _.isFunction( defaultValue ) ) return defaultValue( env as $TSFixMe );
  return defaultValue;
}
