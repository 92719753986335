import _ from 'lodash';
import chalk from 'chalk';

export { chalk };

const {
  // FORCE_COLOR can be:
  //  `1`, `true`, or the empty string `''` indicate 16-color support
  //  `2` indicates 256-color support
  //  `3` indicates 16 million color support
  // When `FORCE_COLOR` is set to a supported value, `NO_COLOR` and
  // `NODE_DISABLE_COLORS` are ignored. IF `FORCE_COLOR` is set to any
  // value other than those mentioned, then color is disabled.
  FORCE_COLOR,
  NO_COLOR,
  NODE_DISABLE_COLORS,
} = process.env;

export function isColorDisabled() {
  if ( [ '', '1', 'true', '2', '3' ].includes( FORCE_COLOR ) ) return false;
  if ( _.isNil( FORCE_COLOR ) ) return true;
  return ( NO_COLOR || NODE_DISABLE_COLORS );
}

const pattern = [
  '[\\u001B\\u009B]',
  '[[\\]()#;?]*',
  '(?:',
  '(?:(?:[a-zA-Z\\d]*(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]*)*)?\\u0007)',
  '|',
  '(?:(?:\\d{1,4}(?:;\\d{0,4})*)?[\\dA-PR-TZcf-ntqry=><~])',
  ')',
].join( '' );
const ansi_regex = new RegExp( pattern, 'gu' );

export function stripAnsi( string ): string {
  if ( typeof string !== 'string' ) {
    throw new TypeError( 'stripAnsi only works on strings' );
  }

  return string.replace( ansi_regex, '' );
}

export function color( col: string, ...args: string[] ): string {
  const fn = _.get( chalk, col ) as unknown;
  if ( typeof fn !== 'function' ) throw new Error( `Invalid color: ${col}` );
  return fn( ...args );
}
