import _ from 'lodash';
import { env } from './env';

const envs = [ 'dev', 'test', 'prod', 'mgmt' ] as const;

export type Env = typeof envs[number];

const csn_domains = [
  'boozallencsn.com',
  'lnx.boozallencsn.com',
];
const ci_build_hosts = [
  /^jenkins-slave-/u,
  /^jenkins-agent-/u,
];

/**
 * Get the current hostname.  In a server build this will be the
 * hostname of the machine the code is running on (as returned by the
 * `os` module's `hostname` method), in a browser build it will return
 * `window.location.hostname`.
 */
export function getHostname(): string {
  if ( BUILD.isServer ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require( 'os' ).hostname().toLowerCase();
  } else if ( BUILD.isClient && typeof window === 'undefined' ) {
    return 'unknown-test-host';
  } else {
    return window.location.hostname;
  }
}

export interface HostnameInfo {
  hostname: string;
  host: string;
  domain?: string;
  idx: string;
  env: Env;
  role: string;
  is_csn: boolean;
  is_ci_build_host: boolean;
}

/**
 * Parse out the important pieces of a hostname in our standard
 * format.  The format understood by this function is:
 *  `<role>-<env>-<idx>.<domain>`
 *
 * Where `<role>` is the machine role (`ssp`, `mongodb`, `workers`,
 * etc), `<env>` is the short version of the environment (`dev`,
 * `test`, `prod` or `mgmt`), and `<idx>` is either a single letter
 * (the preferred/common format) or a two-digit number (generally only
 * used for things where we could conceivable end up with more than 26
 * instances of a role in the same environment).
 *
 * @param {string} [hostname] - The hostname to parse.  If not
 * provided, the hostname returned by `getHostname()` will be used.
 */
export function parseHostname( hostname: string ): HostnameInfo {
  /* istanbul ignore next */
  if ( ! hostname ) hostname = getHostname();
  const [ host, ...domain_parts ] = hostname.split( '.' );
  const domain = domain_parts.join( '.' );
  const parts = host.split( '-' );

  // The index is either a single letter, or two numbers
  const idx = /^([a-z]|\d\d)$/u.test( _.last( parts ) ) ? parts.pop() : '_';
  const ssp_env = isEnv( _.last( parts ) ) ? parts.pop() : undefined;

  const role = parts.join( '-' );
  const res: HostnameInfo = {
    hostname, host, domain, idx, role,
    env               : isEnv( ssp_env ) ? ssp_env : undefined,
    is_csn            : csn_domains.includes( domain ),
    is_ci_build_host  : _.some( ci_build_hosts, re => re.test( host ) ),
  };
  if ( ! res.is_csn ) {
    _.defaults( res, {
      env     : 'dev',
      role    : 'devhost',
      domain  : 'local',
    } );
  }
  return res;
}
export function isEnv( arg: any ): arg is Env {
  return envs.includes( arg );
}

/**
 * Get the short environment name for a hostname (`dev`, `test`,
 * `prod` or `mgmt`).
 *
 * @param {string} [hostname] - The hostname to parse.  If not
 * provided, the hostname returned by `getHostname()` will be used.
 * @param {boolean} [fromEnvironment=true] - Set to true to also allow
 * environment variables to provide the env (`$SSP_ENV`,
 * `$SSP_WORKER_ENV`).  Note that fromEnvironment only applies when
 * you don't specify a hostname, if you directly provide a hostname
 * then the environment for that hostname will be returned.
 */
export function getEnv( hostname=getHostname(), fromEnvironment=true ) {
  if ( fromEnvironment ) {
    for ( const e of [ 'SSP_ENV', 'SSP_WORKER_ENV' ] ) {
      const val = env( e );
      if ( isEnv( val ) ) return val;
    }
  }
  if ( hostname ) return parseHostname( hostname ).env;
  throw new Error( `Unable to determine env` );
}

export function isLocalDevMode() {
  const host = getHostname();
  for ( const domain of csn_domains ) {
    if ( host.endsWith( '.' + domain ) ) return false;
  }
  return true;
}

export function getHostId() {
  return env( 'SSP_HOST_ID', () => getHostname().replace( /\..*$/u, '' ) );
}
