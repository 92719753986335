import { findErrorType } from './serialization';

import type { AnyError } from './AnyError';

export type ConstructorSearch = string | number | undefined | null | {
  code?: number;
  statusCode?: number | string;
  status?: string;
} | AnyError;

/**
 * Find an appropriate error constructor (most likely for wrapping
 * some other error).
 * This only searches registered error classes that extend from
 * SSPError. You can give it several arguments to search by multiple
 * properties.
 *
 * Acceptable argument types:
 *  - Any error instance. If it has `code`, `status`, or `statusCode`
 *    properties then it will search with those values.
 *  - A string - Can be the name of an error constructor class, or the
 *    value of the static `status` property on one of them.
 *  - A number - Can be the value of the static `code` property on an
 *    error constructor class.
 *  - An object - If you include an options object and it contains
 *    properties like `code` or `status` then they will be used to
 *    find an error class as above.
 */
export function findErrorConstructor( ...args: ConstructorSearch[] ) {
  return findErrorType( ...process( ...args ) );
}
function process( ...args: ConstructorSearch[] ): string[] {
  return args.flatMap( arg => {
    if ( ! arg ) return [];
    if ( typeof arg === 'string' ) return arg;
    if ( typeof arg === 'number' ) return String( arg );
    if ( typeof arg === 'object' ) {
      return process( arg.code, arg.status, arg.statusCode );
    }
    return [];
  } );
}
