import { GeneralError } from './common';

export type DatabaseErrorData = {
  /** Parameter or field name. */
  field?: string;
};
export type DatabaseErrorTags = {
  /** Schema name. */
  schema?: string;
  /** Method name. */
  method?: string;
};

export class DatabaseError<
  Data extends DatabaseErrorData = DatabaseErrorData,
  Tags extends DatabaseErrorTags = DatabaseErrorTags,
> extends GeneralError<Data, Tags> {
  static readonly status = 'database-error';
  static readonly message = 'Database Error';

  get schema() { return this.tags?.schema; }
  get field() { return this.data?.field; }
}

export class DatabaseTransportError extends DatabaseError {
  static readonly code = 500;
}
export class DatabaseMissingDataError extends DatabaseTransportError {
}
export class DatabaseResultSetError extends DatabaseError {
}
export class DatabaseSchemaError extends DatabaseError {
}
export class DatabaseModelError extends DatabaseError {
}
export class DatabaseOriginsError extends DatabaseError {
}
export class DatabaseBrokerError extends DatabaseError {
}
export type DatabaseFieldErrorTags = DatabaseErrorTags & {
  /** Field name. */
  field: string;
};
export class DatabaseFieldError extends DatabaseError<
  DatabaseErrorData, DatabaseFieldErrorTags
> {
}

export type DatabaseEventErrorTags = DatabaseErrorTags & {
  /** Event name. */
  event: string;
};
export class DatabaseEventError extends DatabaseError<
  DatabaseErrorData, DatabaseEventErrorTags
> {
}
