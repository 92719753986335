import _ from 'lodash';
import { DatabaseError } from './errors';
import { getRootURL } from './urls';

import type { DatabaseErrorData } from './errors';

export type ReferenceURLErrorData = DatabaseErrorData & {
  /** Reference URL. */
  origin_url: string;
};
export class ReferenceURLError extends DatabaseError<ReferenceURLErrorData> {
}

const SHORT_MAP: Record<string, string> = {
  'SYSTEM.Job'        : 'job',
  'SSP.Project'       : 'project',
  'SSP.User'          : 'user',
};
const SHORTER_MAP: Record<string, string> = {
  'SSP.Project'       : 'p',
  'SSP.User'          : 'u',
};
const ONLY_ID_TYPES = [ 'SYSTEM.Job' ];

const TYPE_MAP: Record<string, string> = {};
_.each( SHORT_MAP, ( val, key ) => TYPE_MAP[ val ] = key );
_.each( SHORTER_MAP, ( val, key ) => TYPE_MAP[ val ] = key );

const SPECIAL_KEYS = [ 'ref', 'resource' ];

/*
 * We reserve all the single-letter field names for use in
 * ReferenceURLs as aliases for other things (to help keep them as
 * short as possible).  Currently these are the single-letter names
 * that are in use:
 *
 * m - matched (indicates matching search field)
 * n - name (`display_name` or `name`)
 * v - version (`_version`)
 */
const PROP_NAMES: Record<string, string[]> = {
  name    : [ 'n', 'display_name' ],
  id      : [ '_id' ],
  version : [ 'v', '_version', 'version' ],
};
const SPECIAL_FIELDS = _.flatMap( PROP_NAMES, ( v, k ) => [ k, ...v ] );
const PROP_MAP = {};
_.each( PROP_NAMES, ( aliases, name ) => {
  PROP_MAP[ name ] = name;
  _.each( aliases, alias => PROP_MAP[ alias ] = name );
} );

export class ReferenceURL {

  /**
   * The reference URL that was parsed (if this object was created by
   * parsing a URL string).
   */
  get url(): string {
    if ( this._url ) return this._url;
    return this.createURL().href;
  }

  /** The origin of the reference URL. */
  origin?: string;

  static get origin() { return ReferenceURL.getOrigin(); }

  /**
   * Will be true if the reference URL was valid and parsed without
   * errors.
   */
  get valid(): boolean { return this.errors.length === 0; }

  /**
   * Check whether the ReferenceURL is fully resolved (meaning we can
   * create a skeleton that includes the primary id without a trip to
   * the database).
   */
  get resolved(): boolean { return Boolean( this.type && this.id ); }

  /**
   * The key used to identify the type of reference url.  This will be
   * `ref` for fully-qualified references, but there are other types
   * of reference urls that may include `project`, `user`, `job` or
   * `resource`.
   */
  key?: string;

  get shortKey() { return SHORT_MAP[ this.key ]; }
  get shorterKey() { return SHORTER_MAP[ this.key ]; }

  /** The type of the resource. */
  declare type?: string;

  /** The primary id of the resource. */
  declare id?: string;

  /** A unique identifier of the resource. */
  declare ident?: string;

  /** The version of the resource represented by this ReferenceURL. */
  get version() { return this._version && _.toInteger( this._version ); }
  set version( v ) { this._version = v; }
  declare _version?: number;

  /**
   * An object containing any other fields (other than id and version)
   * that were included in the reference URL.
   */
  get fields() { return this._fields; }
  set fields( data ) {
    _.each( data, ( val, key ) => this.setField( key, val ) );
  }
  _fields: Record<string, any> = {};

  /**
   * The first error that was encountered while parsing or generating
   * this Reference URL.
   */
  get error(): string { return this.errors[ 0 ]; }

  /**
   * An array containing any error messages that were generated during
   * parsing.
   */
  get errors(): string[] {
    return Object.keys( this )
      .filter( k => k.startsWith( '_error_' ) )
      .map( k => this[ k ] );
  }

  hasValidOrigin() {
    const origin = this.origin;
    const ORIGIN = ReferenceURL.getOrigin();
    const isLocal = val => /localhost/u.test( val ) || /\.local/u.test( val );
    return ( origin === ORIGIN ) || ( isLocal( origin ) && isLocal( ORIGIN ) );
  }

  /**
   * Any extra pathinfo beyond what was required by the parser.  If
   * the URL includes extra pathinfo then it's not valid as
   * a Reference URL, since the extra pathinfo might change the
   * resource that should be returned.  Take a URL with a path of
   * `/project/SSP/team/Administrators`, for example.  This would
   * be turned into a skeleton resource for the SSP Project, when the
   * URL actually referred to one of the projects teams.
   */
  extra?: string;

  safe: boolean = false;

  constructor( data?: string | Partial<ReferenceURL>, options = {} ) {
    _.assign( this, options );

    // From a ref_url
    if ( _.isString( data ) ) this.parse( data );
    // From a data object
    if ( _.isPlainObject( data ) ) this.process( data );
  }

  isValidKey( key ) {
    if ( ! _.isString( key ) ) return false;
    if ( SPECIAL_KEYS.includes( key ) ) return true;
    if ( TYPE_MAP[ key ] ) return true;
    const parts = key.split( '.' );
    for ( const part of parts ) {
      if ( ! /^[A-Z]\w+[a-z]$/u.test( part ) ) return false;
    }
    return true;
  }

  declare _url?: string;

  [key: `_error_${string}`]: string;

  /** Parse a reference URL into a new ReferenceURL object. */
  parse( ref_url: string ) {
    this._url = ref_url;
    if ( ! ReferenceURL.maybe( ref_url ) ) {
      this._error_url = 'Does not look like a ReferenceURL';
      return this;
    }
    let url;
    try {
      url = new URL( ref_url );
    } catch ( err ) {
      this._error_url = err.message;
      return this;
    }

    const parts = url.pathname.split( '/' ).filter( x => x.length > 0 );
    this.key = parts.shift();
    this.origin = url.origin;

    if ( this.key === 'ref' ) {
      // https://ssp/ref/<TYPE>/<ID>
      this.type = parts.shift();
      this.id = parts.shift();
    } else if ( this.key === 'resource' ) {
      // https://ssp/resource/<ID>
      this.id = parts.shift();
    } else if ( TYPE_MAP[ this.key ] ) {
      // https://ssp/project/<IDENT>
      // https://ssp/user/<IDENT>
      // https://ssp/job/<ID>
      this.type = TYPE_MAP[ this.key ];
      if ( ONLY_ID_TYPES.includes( this.type ) ) {
        this.id = parts.shift();
      } else {
        this.ident = parts.shift();
      }
    }
    if ( parts.length > 0 ) this.extra = parts.join( '/' );

    for ( const [ name, value ] of url.searchParams ) {
      this.setField( name, value );
    }
    return this;
  }

  setField( name, value ) {
    const prop = PROP_MAP[ name ];
    if ( prop ) {
      this[ prop ] = value;
    } else {
      this.fields[ name ] = value;
    }
  }

  /**
   * Process a data object into this ReferenceURL object.
   *
   * @param {object} data - The data to process.
   */
  process( data ) {
    const { type, id, ident, key, version, v, ...fields } = data;
    if ( _.isObject( fields.fields ) ) {
      _.assign( fields, fields.fields );
      delete fields.fields;
    }
    _.assign( this, { type, id, ident, key, version : version || v } );
    _.assign( this.fields, fields );
  }

  createURL( style?: 'canonical' | 'shorter' | 'short' | 'resource' ) {
    const url = new URL( ReferenceURL.getOrigin() );
    if ( this.name && this.name !== this.id ) {
      url.searchParams.set( 'n', this.name );
    }
    if ( this.version ) url.searchParams.set( 'v', String( this.version ) );
    _.each( _.omit( this.fields, SPECIAL_FIELDS ), ( val, key ) => {
      if ( ! ( _.isString( val ) || _.isNumber( val ) ) ) return;
      url.searchParams.set( key, val );
    } );

    const defaults = [ 'shorter', 'short', 'canonical', 'resource' ];
    const types = _.compact( _.uniq( _.flattenDeep( [ style, defaults ] ) ) );
    for ( const type of types ) {
      const pathMethod = `${type}URLPathParts`;
      const pathparts = this[ pathMethod ]();
      if ( pathparts ) {
        url.pathname = _.map( pathparts, part => {
          if ( part.includes( '/' ) ) {
            // TODO - improve this encoding..
            part = part.replace( /\//gu, '%5C' );
          }
          return part;
        } ).join( '/' );
        return url;
      }
    }
    this.throw( 'Unable to construct a ReferenceURL', {
      method : 'ReferenceURL#createURL', style,
    } );
  }

  /**
   * Construct a URL object for a canonical reference.
   */
  canonicalURLPathParts() {
    const { type, id } = this;
    if ( type && id ) {
      // https://ssp/ref/<TYPE>/<ID>
      return [ 'ref', type, id ];
    }
  }

  shortURLPathParts() {
    const { shortKey, id, ident } = this;
    // https://ssp/<SHORT_TYPE>/<IDENT>
    if ( shortKey && ( id || ident ) ) return [ shortKey, id || ident ];
  }

  shorterURLPathParts() {
    const { shorterKey, id, ident } = this;
    // https://ssp/<SHORTER_TYPE>/<IDENT>
    if ( shorterKey && ( id || ident ) ) return [ shorterKey, id || ident ];
  }

  resourceURLPathParts() {
    const { id } = this;
    // https://ssp/resource/<ID>
    if ( id ) return [ 'resource', id ];
  }

  /**
   * Check whether a string contains a valid reference URL and that it
   * has the correct origin for this instance.
   *
   * @param {string} str - The string to check.
   * @returns {boolean} - Returns true if the string is a valid
   * Reference URL.
   */
  static is( str ) {
    return this.maybe( str ) && ( new this( str ) ).valid;
  }
  static maybe( str ) {
    return _.isString( str ) && _.startsWith( str, this.origin );
  }

  toLinkData() {
    return _.pick( this, 'type', 'id', 'name' );
  }

  get partials() {
    return _.defaults(
      {},
      this.fields,
      { _id : this.id, display_name : this.name },
    );
  }

  throw( message: string, data: Record<string, any> = {} ) {
    throw new ReferenceURLError( message, {
      message,
      errors  : this.errors,
      thrower : this.throw,
      data    : {
        origin_url  : this._url,
        type        : this.type,
        id          : this.id,
        ident       : this.ident,
        name        : this.name,
        ...data,
      },
    } );
  }

  toString() { return this.url; }

  get_validation_errors() {
    if ( this._error_url ) return [ `Invalid ref_url provided` ];

    const errors = [];
    if ( ! this.isValidKey( this.key ) ) {
      errors.push( `Unknown key "${this.key}"` );
    }
    if ( this.extra ) {
      errors.push( `Extra pathinfo in URL` );
    }
    if ( ! ( this.id || this.ident ) ) {
      errors.push( `No identifiers found` );
    }
    if ( this.ident && ! this.type ) {
      errors.push( `Can't use ident without type` );
    }

    if ( ! this.hasValidOrigin() ) {
      errors.push( `Origin "${this.origin}" is not "${ReferenceURL.origin}"` );
    }

    const ignoreProps = [ 'id', 'ident', 'type' ];
    _.each( this.fields, ( _value, name ) => {
      if ( name in ignoreProps ) {
        errors.push( `Query params cannot include "${name}"` );
      }
    } );

    return errors;
  }

  validate( opts={} ) {
    _.assign( this, opts );
    const errors = this.get_validation_errors();

    const req = this.requiredType;
    if ( req && req !== this.type ) {
      errors.push( `Type ${this.type} is not ${req}` );
    }
    if ( this.ident && ( this.type === 'Resource' || ! this.type ) ) {
      errors.push( `Cannot resolve ident without type` );
    }

    this._error_validate = errors;
    if ( this.safe ) return this;
    if ( this.error ) {
      this.throw( this.error, { method  : 'ReferenceURL#validate' } );
    }
    return this;
  }

  declare _name?: string;
  get name() {
    return this._name
      || this.fields?.display_name
      || this.fields?.name
      || this.id;
  }
  set name( name ) { this._name = name; }

  get display_name() { return this.name; }
  set display_name( name ) { this.name = name; }

  static getOrigin() {
    if ( BUILD.isServer && process.env.SSP_REF_URL_ORIGIN ) {
      return process.env.SSP_REF_URL_ORIGIN;
    }
    return getRootURL();
  }

  requiredType?: string;

  static parse( ref_url, options={} ) {
    if ( ref_url instanceof this ) return ref_url.validate( options );
    const { type, ...opts } = options;
    return new this( ref_url, { requiredType : type, ...opts } );
  }

  static hydrate( ref_url, options={} ) {
    return this.parse( ref_url, options ).validate().hydrate( options );
  }

  toJSON() { return this.url; }
}

/**
 * Parse a reference url into it's properties.  Returns an object
 * containing the parsed data and meta information from the reference
 * url.  This data will always include either an `id` or `ident`
 * property (an `id` property will always contain a database primary
 * id, while an `ident` property might be some other unique
 * identifier.  It might include a `type` property that indicates the
 * type of resource that it is a reference to.  If the ref_url
 * includes other properties (like `name`) that are simply to provide
 * more context about the thing being referenced, they will be
 * included in a `fields` property.
 *
 * @param {string} ref_url - The reference URL to parse.
 * @param {object} [options={}] - Options object.
 * @param {boolean} [options.type] - Specify a type, and make
 * validation fail if the ref_url is otherwise valid, but specifies
 * a different type than the one provided here.
 * @param {boolean} [options.safe=false] - Do not throw an exception
 * if there are problems with URL, instead return the information that
 * it was able to parse.
 * @returns {ReferenceURL} - Returns an object containing the
 * referenced items properties.
 */
export function parseRefUrl( ref_url, options={} ) {
  return ReferenceURL.parse( ref_url, options );
}

/**
 * Check whether a string contains a valid reference URL and that it
 * has the correct origin for this instance.
 *
 * @param {string} str - The string to check.
 * @returns {boolean} - Returns true if the string is a valid
 * Reference URL.
 */
export function isRefUrl( str ) { return ReferenceURL.is( str ); }

/**
 * Create a Reference URL.
 *
 * Note that the parameters include two similar options: `id` and
 * `ident`.  If you are creating a Reference URL and you know that the
 * identifier you have is the primary database id for the resource,
 * then use `id`.  If you aren't sure, and the value you have might be
 * some other identifying field besides the primary id, then use
 * `ident`.  Try to use primary ids whenever possible.
 *
 * - Either `id` or `ident` must be included, but if you include both
 *   then one of them will be ignored (if the type is known then `id`
 *   will be ignored, if the type is not known then `ident` will be
 *   ignored).
 * - If `ident` is included then you must also include `type`.
 * - If `type` is not known then you must provide `id`.  You can't
 *   create a Reference URL with just an `ident` because there is no
 *   general way to resolve an `ident` without knowing the `type`.
 *
 * @param {object} data - The Reference URL properties.
 * @param {string} [data.type] - The resource type.
 * @param {string} [data.id] - The resources primary id.
 * @param {string} [data.ident] - The resources ident string.
 * @param {number} [data.version] - The resource version.
 * @param {string} [data.name] - The resource name.
 * @param {object} [data.fields] - Additional fields to include in the URL.
 *
 * Note that any properties included in the `data` object that are not
 * listed as parameters will be treated as `fields`.  This allows you
 * to say
 * ```
 *    createRefUrl( { type, id, name } );
 * ```
 * instead of
 * ```
 *    createRefUrl( { type, id, fields : { name } } );
 * ```
 */
export function createRefUrl( data ) {
  return ( new ReferenceURL( data ) ).url;
}
