export function tryPromise( func ) {
  if ( typeof func === 'function' ) {
    return new Promise( ( resolve, reject ) => {
      try { resolve( func() ); } catch ( err ) { reject( err ); }
    } );
  } else if ( func instanceof Error ) {
    return Promise.reject( func );
  } else {
    return Promise.resolve( func );
  }
}
