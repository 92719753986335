import js_yaml, { DumpOptions, LoadOptions } from 'js-yaml';
import {
  jsonSerialize, JsonSerializeOptions,
  jsonDeserialize, JsonDeserializeOptions,
} from './json';

/* Custom types
class CustomTag {
  constructor( type, data ) { _.assign( this, { type, data } ); }
}

// first argument is a prefix, so this type will handle anything
// starting with !
const tags = [
  'scalar', 'sequence', 'mapping',
].map( ( kind ) => new js_yaml.Type( '!', {
  kind, multi: true,
  representName( object ) { return object.type; },
  represent( object ) { return object.data; },
  instanceOf: CustomTag,
  construct( data, type ) { return new CustomTag( type, data ); },
} ) )
*/

const schema = js_yaml.DEFAULT_SCHEMA.extend( [] );

export interface YamlLoadOptions extends LoadOptions, JsonDeserializeOptions {
  /** If true then load multi-document sources. */
  all?: boolean;
}
/**
 * Load YAML from a string.
 *
 * @param string - The string containing YAML.
 *
 */
export function yamlLoad( text: string, options: YamlLoadOptions = {} ) {
  const { all, ...opts } = options;
  if ( all ) {
    return js_yaml.loadAll( text, { ...opts, schema } )
      .map( jsonDeserialize );
  } else {
    return jsonDeserialize( js_yaml.load( text, { ...opts, schema } ) );
  }
}

export interface YamlDumpOptions extends DumpOptions, JsonSerializeOptions {
  /**
   * If true ensure that document starts with the document start
   * indicator (`---`) even if not strictly required.
   */
  docstart?: boolean;
  /**
   * If true ensure that document ends with the document end indicator
   * (`...`) even if not strictly required.
   */
  docend?: boolean;
}
/**
 * Dump object to YAML string.
 *
 * @param item - The object to dump to YAML.
 */
export function yamlDump( item: any, options: YamlDumpOptions = {} ) {
  const { docstart, docend, ...opts } = options;
  let res = js_yaml.dump( jsonSerialize( item, opts ), {
    skipInvalid   : true,
    flowLevel     : -1,
    sortKeys      : false,
    noRefs        : false,
    noCompatMode  : true,
    condenseFlow  : false,
    quotingType   : "'",
    forceQuotes   : false,
    ...opts,
    schema,
    spaces        : 2,
    noArrayIndent : false,
    styles        : {},
    lineWidth     : 80,
    // replacer
  } ).trim();
  if ( docend ) {
    // If you want the end marker, you must have both.
    if ( ! res.startsWith( '---' ) ) res = '---\n' + res;
    if ( ! res.endsWith( '\n...' ) ) res += '\n...';
    return res + '\n';
  }
  if ( docstart ) {
    if ( res.startsWith( '--- ' ) || res.startsWith( '---\n' ) ) return res;
    if ( res.includes( '\n' ) ) {
      return '---\n' + res + '\n';
    } else {
      return '--- ' + res + '\n';
    }
  }
  return res + '\n';
}

export const yaml = { load : yamlLoad, dump : yamlDump };
