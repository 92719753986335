import _ from 'lodash';
import { isString, isRecord, isNil, isArray } from '../types';
import { log } from '../log';

import type { Squishy } from '../arrays';

export const registry: Set<string> = new Set();

export type SecretSource = string | { username?: string; password: string; };
/**
 * Add one or more secret values to the secrets registry.  All strings
 * in the secrets registry will be sanitized when scrubbing.
 *
 * @param secrets - The secrets to add to the registry.
 */
export function registerSecrets( ...secrets: Squishy<SecretSource>[] ) {
  for ( const secret of secrets ) registerSecret( secret );
}
function registerSecret( secret: Squishy<SecretSource> ) {
  if ( isNil( secret ) ) return;
  if ( ! secret ) return;
  if ( isArray( secret ) ) return registerSecrets( ...secret );
  if ( isString( secret ) ) return registry.add( secret );
  if ( isRecord( secret ) ) {
    if ( isString( secret.password ) ) {
      const { username : user = '', password : pass } = secret;
      const encoded = Buffer.from( user + ':' + pass ).toString( 'base64' );
      return registerSecrets( pass, encoded );
    }
  }
  log.warn( `Cannot add ${secret} to secrets registry` );
  throw new TypeError( `Can only add strings to the secrets registry` );
}

export interface RegisterSecretsFromOptions {
  secretKeys?: string[];
  isSecretKey?: ( key: string ) => boolean;
}
export type ObjectWithSecretKeys = any & RegisterSecretsFromOptions;

/**
 * Given an object (likely containing configuration data), attempt to
 * determine which keys contain secrets and register their contents.
 *
 * This allows you to provide a `secretKeys` array in a configuration
 * object, and have it's secrets masked automatically.
 *
 * @param obj - The object to check.
 * @param {string[]} [obj.secretKeys] - An array of secret keys to
 * check for.
 * @param {object} opts - Options.
 * @param {string[]} [opts.secretKeys] - An array of secret keys to
 * check for.
 * @param {Function} [opts.isSecretKey] - A function that can take
 * a key name and return true if that key represents a secret value.
 */
export function registerSecretsFrom(
  obj: ObjectWithSecretKeys,
  opts: RegisterSecretsFromOptions = { isSecretKey },
) {
  if ( ! isRecord( obj ) ) {
    throw new Error( `Can only registerSecretsFrom for object` );
  }

  const checkers: ( ( key: string ) => boolean )[] = [];
  if ( obj.secretKeys ) {
    checkers.push( key => obj.secretKeys.includes( key ) );
  }
  if ( opts.secretKeys ) {
    checkers.push( key => opts.secretKeys.includes( key ) );
  }
  if ( obj.isSecretKey ) checkers.push( obj.isSecretKey );
  if ( opts.isSecretKey ) checkers.push( opts.isSecretKey );
  const isSecret = _.overSome( checkers );
  for ( const key of Object.keys( obj ) ) {
    if ( typeof obj[key] === 'string' && isSecret( key ) ) {
      registerSecrets( obj[key] );
    }
  }
}

/**
 * Check whether a secret is in the registry.
 *
 * @param {string} secret - The secret to check.
 * @public
 */
export function hasSecret( secret: string ): boolean {
  return registry.has( secret );
}

/**
 * Get the secrets from the registry sorted by length (longest first).
 *
 * @private
 */
export function getSecrets() {
  return _.sortBy( Array.from( registry ), 'length' ).reverse();
}

function isSecretKey( key: string ): boolean {
  return key.includes( 'token' )
    || key.includes( 'password' )
    || key.includes( 'secret' );
}
