import _ from 'lodash';
import getSource from 'get-source';
import StackTrace, { StackFrame } from 'stacktrace-js';
import ErrorStackParser from 'error-stack-parser';

import { yamlDump } from '../yaml';
import { StackItem } from './StackItem';

import type { ItemMatcher } from './StackItem';

export type StackOptions = {
  /** Hide frames that match */
  hide?: ItemMatcher;
  /** Hide frames that match, but only until a non-matching frame is found. */
  hide_initial?: ItemMatcher;
  /** Un-hide frames that match */
  show?: ItemMatcher;
  thrower?: ( ...args: any[] ) => any|never;
  hide_error_setup?: boolean;
  offset?: number;
};

export class Stack {

  static capture( options: StackOptions = {} ) {
    const items = StackTrace.getSync()
      .map( frame => StackItem.fromStackFrame( frame ) )
      .filter( x => ( x instanceof StackItem ) );
    return new Stack( items, { ...options, offset : 2 } );
  }

  static fromError( error: Error, options?: StackOptions ) {
    const frames = ErrorStackParser.parse( error ) as unknown as StackFrame[];
    const items = frames
      .map( ( frame: StackFrame ) => StackItem.fromStackFrame( frame ) )
      .filter( x => ( x instanceof StackItem ) );
    return new Stack( items, options );
  }
  static deserialize( data: {
    items: Partial<StackItem>[];
    options: Partial<Stack>;
  } ): Stack {
    const frames = ErrorStackParser.parse( error ) as unknown as StackFrame[];
    const items = frames
      .map( ( frame: StackFrame ) => StackItem.fromStackFrame( frame ) )
      .filter( x => ( x instanceof StackItem ) );
    return new Stack( items, options );
  }

  items: StackItem[];

  options: StackOptions = {};

  constructor( items: StackItem[], options: StackOptions = {} ) {
    const { offset = 0, ...opts } = options;
    this.items = items.slice( offset );
    Object.assign( this.options, opts );
    if ( opts.hide ) this.hide( opts.hide );
    if ( opts.hide_initial ) this.hide_initial( opts.hide_initial );
    if ( opts.show ) this.show( opts.show );
  }

  prepare() { return this.items.map( item => item.prepare() ); }
  prepareAsync() { return Promise.all( this.prepare() ); }

  toString() {
    this.prepare();
    return this.items
      .filter( item => ! item.is_hidden )
      .map( item => item.toString() )
      .join( '\n' );
  }

  formatDetails() {
    this.prepare();
    return yamlDump( this.items.map( item => item.toJSON() ) );
  }

  hide( ...matchers: ItemMatcher[] ) {
    for ( const item of this.items ) {
      if ( item.matches( ...matchers ) ) item.is_hidden = true;
    }
  }

  hide_initial( ...matchers: ItemMatcher[] ) {
    for ( const item of this.items ) {
      if ( item.matches( ...matchers ) ) {
        item.is_hidden = true;
      } else {
        break;
      }
    }
  }

  show( ...matchers: ItemMatcher[] ) {
    for ( const item of this.items ) {
      if ( item.matches( ...matchers ) ) item.is_hidden = false;
    }
  }

  clean() {
    const items = this.items.filter( item => item.is_clean );
    return new Stack( items, this.options );
  }

  at( i ) { return this.items[ i ]; }

  static resetCaches() {
    getSource.resetCache();
    getSource.async.resetCache();
  }

  toJSON() {
    return _.pickBy( this, ( val, key ) => {
      if ( key.startsWith( 'is_' ) && ! val ) return false;
      if ( _.isNil( val ) ) return false;
      if ( [
        'sourceFile',
      ].includes( key ) ) return false;
      return true;
    } );
  }

  static fromJSON( data ) {
    return Object.create( this.prototype, _.mapValues( {
      ...data,
      items : data.items?.map( d => StackItem.fromJSON( d ) ),
    }, value => ( {
      value,
      // enumerable    : true,
      // configurable  : false,
      // writable      : false,
    } ) ) );
  }

}
