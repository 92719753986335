import _ from 'lodash';
import urlcat from 'urlcat';
import braces from 'braces';
import { env } from './env';

export function getRootURL() { return env( 'ROOT_URL' ); }

export function resolveURL( ...args ) {
  args = _.flattenDeep( args );
  const opts = _.assign( {}, ..._.filter( args, _.isPlainObject ) );
  const parts = _.compact( _.filter( args, _.isString ) );
  const root = /^\w+:\/\//u.test( parts[0] ) ? parts.shift() : getRootURL();
  const path = _.compact( parts ).join( '/' ).replace( /\/+/gu, '/' );
  return urlcat( root, path, opts );
}

export function resolveRoute( ...args ) {
  const url = new URL( resolveURL( args ) );
  return url.href.substring( url.origin.length );
}

export interface NormalizeUrlOptions {
  auth?: boolean;
  username?: boolean|string;
  password?: boolean|string;
  array?: boolean;
}
export function normalizeURL( url: string, opts: NormalizeUrlOptions = {} ) {
  if ( opts.auth ) opts.username = opts.password = true;
  if ( ! _.isString( url ) ) {
    throw new TypeError( `Invalid argument "${url}" to normalizeURL` );
  }
  // braces doesn't support the square-brackets notation, but we can
  // convert it to a form that works
  url = url.replace( /\[(.*?)\]/ug, ( _x, val ) => `{${val.split( '' )}}` );
  // We also want to handle MongoDB-style URLs with multiple hosts
  // separated with commas
  if ( url.includes( ',' ) && ! url.includes( '{' ) ) {
    url = url.replace( /^(\w+:\/\/)([^/]+)(.*)$/u, ( _x, pre, mid, post ) => {
      if ( mid.includes( ',' ) ) {
        return pre + '{' + mid + '}' + post;
      } else {
        return pre + mid + post;
      }
    } );
  }
  // eslint-disable-next-line no-shadow
  const urls = braces.expand( url ).map( raw => {
    const u = new URL( raw );
    if ( _.isString( opts.username ) ) {
      u.username = opts.username;
    } else if ( ! opts.username ) {
      u.username = '';
    }
    if ( _.isString( opts.password ) ) {
      u.password = opts.password;
    } else if ( ! opts.password ) {
      u.password = '';
    }
    return u.href;
  } );
  if ( opts.array ) return urls;
  if ( urls.length === 0 ) return;
  if ( urls.length === 1 ) return urls[0];
  return urls;
}
