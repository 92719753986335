import { SSPError } from './SSPError';

import type { Merge } from '@ssp/ts';

import type { ErrorData, ErrorTags, ErrorOpts } from './types';

export type TemporaryErrorOptions = {
  /** Minimum time to wait before retrying. */
  retryafter?: string;
};

export abstract class TemporaryError<
  Data extends ErrorData = ErrorData,
  Tags extends ErrorTags = ErrorTags,
  Opts extends ErrorOpts = ErrorOpts
> extends SSPError<Data, Tags, Merge<Opts, TemporaryErrorOptions>> {
  static readonly is_temporary = true;
  static readonly message = 'Temporary error';

  readonly is_temporary = true;

  /** Minimum time to wait before retrying. */
  retryafter: string = '10 seconds';
}
