import _ from 'lodash';
import { get, create, StorePublic, CreateOptions } from './create';
import { MergerName, MergerFn, getMerger } from './mergers';

import type { GlobalRegistries } from '.';

/**
 * A registry is similar to a store, but instead of one single "chunk"
 * of data, you can add different pieces of data from different
 * locations and they get merged together into one big state that can
 * be subscribed to.  A good example of this is the registration of
 * keyboard shortcuts in the webapp.  When a component is mounted and
 * uses the `useShortcut` hook to register a keyboard shortcut, the
 * configuration of that shortcut is registered when the component
 * mounts and unregistered when it unmounts, but when you type
 * a keyboard shortcut, the event handler for that needs to know all
 * of the shortcuts that have been registered by all mounted
 * components.
 */
export type Registry<Output = unknown, Input = unknown> = StorePublic<Output, {
  /**
   * Register a chunk of data in the registry.
   *
   * @param data - The data to register.
   * @returns A disposer function to unregister the data.
   */
  register( data: Input ): () => void;
  /**
   * Update a chunk of data in the registry.  This can be useful in
   * some cases where you want to control the ID instead of letting
   * the register method create one for you.  For example, we use this
   * to configure the global keyboard shortcuts that never change, rather
   * than registering them.
   *
   * @param id - The id to register it under.
   * @param data - The data to register.
   */
  update( id: string, data: Input ): void;
  /**
   * Unregister a chunk of data from the registry.  You only need this
   * if you registered it by calling `update` instead of `register`.
   */
  unregister( id: string ): void;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type RegistryOptions<
  Output = unknown,
  Input = unknown,
  Public extends Record<string, any> = Record<string, never>
> = CreateOptions<Output, Public> & {
  /**
   * If true then merged values will be uniqued.  If a string then it
   * names a property to unique them by. This option is implemented by
   * the default `normalize` method, so if you override `normalize`
   * then it doesn't do anything.
   */
  unique?: boolean | string;
  /**
   * This is a "wrapper" around the configured merge function.  The
   * default normalize method calls `this.merge` to run the configured
   * merge function, then runs it through `_.uniq` or `_.uniqBy` if
   * the `unique` option is set and then returns the results.  You can
   * override this if you need to do some more manipulating of the
   * merged data but you still want to use one of the default merge
   * functions.
   * The default `initialize` function also calls this with an empty
   * object.
   */
  normalize?: ( data: Input ) => Output;
  /**
   * A function that takes an array of registered data and reduces it
   * to the appropriate state data.
   */
  merge?: MergerName | MergerFn<Output, Input>;

  /** A method to be notified when a data item is about to be updated. */
  onUpdate?: ( id: string, data: Output ) => void;
  /** A method to be notified when a data item is about to be registered. */
  onRegister?: ( id: string, data: Input ) => void;
  /** A method to be notified when a data item is about to be unregistered. */
  onUnregister?: ( id: string, data: Input ) => void;
}

export function createRegistry<Input = unknown, Output = Input[]>(
  opts: RegistryOptions<Input, Output>,
): Registry<Input, Output> {
  if ( ! _.isPlainObject( opts ) ) {
    throw new TypeError( `createRegistry requires options object` );
  }
  return create( {
    ...opts,
    merge   : getMerger( opts.merge || ( opts as any ).merger || 'values' ),
    raw     : {},
    counter : 0,
    initialize() { return this.normalize( {} ); },
    normalize( data ) {
      data = this.merge( data );
      if ( _.isString( this.unique ) ) data = _.uniqBy( data, this.unique );
      if ( this.unique === true ) data = _.uniq( data );
      return data;
    },
    public  : {
      update( id, data ) {
        if ( _.isFunction( this.onUpdate ) ) this.onUpdate( id, data );
        this.debug( 'UPDATING REGISTRY:', id, data );
        if ( Object.is( this.raw[id], data ) ) {
          this.debug( '  -> SKIPPING update, the contents are unchanged' );
          return;
        }
        if ( _.isNil( data ) ) {
          this.debug( '  -> Clearing', data, 'for', id );
          delete this.raw[id];
        } else {
          this.raw[id] = data;
        }
        this.state = this.normalize( this.raw );
        this.debug( '  -> NORMALIZED:', this.state );
        for ( const consumer of this.setters.values() ) {
          consumer( this.state );
        }
        this.debug( '  -> NOTIFIED', this.setters.size, 'WATCHERS' );
      },
      unregister( id ) {
        if ( ! id ) return;
        this.debug( 'Unregistering', id );
        const data = this.raw[id];
        this.update( id, null );
        if ( _.isFunction( this.onUnregister ) ) this.onUnregister( id, data );
      },
      register( data ) {
        if ( ! data ) return;
        const id = `${this.name}-${++this.counter}`;
        this.debug( 'Registering', id, data );
        this.update( id, data );
        if ( _.isFunction( this.onRegister ) ) this.onRegister( id, data );
        return () => this.unregister( id );
      },
      ...opts.public,
    },
  } );
}

export function getRegistry<
  Name extends keyof GlobalRegistries,
>( name: Name ): GlobalRegistries[Name] extends [infer S, infer I]
  ? Registry<S, I> | undefined
  : Registry<GlobalRegistries[Name]> | undefined;
export function getRegistry<
  Input = unknown,
  Output = Input[]
>( name: string ): Registry<Input, Output> | undefined;
export function getRegistry( name: string ) { return get( 'Registry', name ); }
