import _ from 'lodash';
import DEBUG from 'debug';

import type { Debug } from 'debug';

export interface DebugOptions {
  description?: string;
  env?: string;
}

// DEBUG.log = debug_logger.bind( DEBUG );

process.env.DEBUG_HIDE_DATE = 'true';

export function mkdebug( name: string, opts?: DebugOptions|string ) {
  if ( typeof SSP !== 'undefined' ) SSP?.utils?.debug?.register( name, opts );
  const debug = DEBUG( name );
  // debug.log = debug_logger.bind( debug );
  return debug;
}

export type { Debug };

function debug_logger( ...args ) {
  if ( typeof global.log === 'undefined' ) {
    // eslint-disable-next-line no-console
    console.warn( ...args );
  } else {
    const tags = _.isPlainObject( args[0] ) ? args.shift() : {};
    tags.ns = this.namespace;
    global.log.trace( tags, ...args );
  }
}
