import _ from 'lodash';
import chalk from 'chalk';
import { symbols } from '../symbols';
import { AnyError, isAnyError } from './AnyError';
import { isSSPError } from './SSPError';

/**
 * Reduce an error to it's most simplified string form.  This is
 * intended primarily for use cases like the `SYSTEM.Job` components
 * `status_reason` and `error` fields, to simplify an error down to an
 * error message that can be displayed to a user.
 *
 * @param {string|Error} error - The error to stringify.
 */
export function stringifyError( error ) {
  if ( _.isNil( error ) ) return;
  if ( _.isString( error ) ) return error;
  if ( _.isArray( error ) ) {
    const res = _.reject( _.map( error, stringifyError ), _.isNil );
    if ( _.isEmpty( res ) ) return;
    return res.join( '\n' );
  }
  if ( _.isPlainObject( error ) ) {
    const res = _.omitBy( _.mapValues( error, stringifyError ), _.isNil );
    if ( _.isEmpty( res ) ) return;
    return _.map( res, ( val, key ) => `${key}: ${val}` ).join( '\n' );
  }
  if ( _.isFunction( error.stringify ) ) return trimError( error.stringify() );
  // Fallthrough - hope for the best...
  return trimError( error );
}

/**
 * Extract all of the useful properties from an error.
 *
 * @param {Error} error - The error to transform.
 */
export function extractError( error: AnyError ) {
  return _.omitBy( {
    // Fallbacks for some critical properties, in case nothing below
    // provides them...
    name    : 'Error',
    message : extractErrorMessage( error ),
    ..._.pick( error, [
      // These are the properties defined by SSPError
      'code', 'status', 'name', 'cause', 'errors', 'data', 'stack',
      // These are some other useful properties that may not be
      // defined, but if they are we want to keep them...
      'statusCode',
    ] ),
    ...error,
  }, val => {
    return _.isNil( val ) || _.isFunction( val )
      || ( _.isPlainObject( val ) && _.isEmpty( val ) )
      || ( _.isArray( val ) && ! val.length );
  } );
}

// TODO - This needs to handle circularity...
export function extractErrors( error ) {
  if ( _.isError( error ) ) error = extractError( error );
  if ( _.isArray( error ) ) return _.map( error, extractErrors );
  if ( _.isObject( error ) ) return _.mapValues( error, extractErrors );
  return error;
}
export function extractErrorMessage( error ) {
  if ( _.isString( error ) ) return trimError( error );
  if ( _.isString( error._message ) ) return error._message;
  if ( _.isString( error.message ) ) return trimError( error.message );
  return trimError( error );
}
export function trimError( error ) {
  return String( error ).replace( /\n\s+at .*/gums, '' );
}
export function friendlyError( err ) {
  if ( err.is_not_found ) return 'Resource Not Found';
  return extractErrorMessage( err );
}

/**
  * Analyze an error object to determine whether the error is
  * temporary and should be retried later.
  *
  * @param err - The error to analyze.
  * @returns Returns true if this is a retry-able, temporary
  * condition.
  */
export function isTemporaryError( err: AnyError ): boolean {
  if ( err.name === 'TemporaryError' ) return true;
  const codeProps = [ 'code', 'statusCode', 'status' ];
  const temporaries = [
    // No worker available
    'need-worker',
    // SYSTEM.Job throws this to indicate that it needs to wait for
    // a subjob to finish.
    'waiting',
    // This is a timeout rejection from Promise.timeout
    'timeout',
    // Most errors with a `status` or `statusCode` property are HTTP
    // errors.
    420, // Enhance Your Calm
    429, // Too Many Requests
    // Socket timed out.. This could indicate that DNS is slow
    // (among other things)
    'ESOCKETTIMEDOUT',
    // Address was already in use
    'EADDRINUSE',
    // connection refused
    'ECONNREFUSED',
    // connection reset by peer
    'ECONNRESET',
    // operation timed out
    'ETIMEDOUT',
    // broken pipe
    'EPIPE',
    // Too many open file descriptors
    'EMFILE',
    // Data could not be sent on a socket
    'ERR_SOCKET_CANNOT_SEND',
    // A TLS/SSL handshake timed out
    'ERR_TLS_HANDSHAKE_TIMEOUT',
    // An attempt to renegotiate the TLS session failed.
    'ERR_TLS_RENEGOTIATE',
    // TTY initialization failed due to a system error
    'ERR_TTY_INIT_FAILED',
  ];
  for ( const prop of codeProps ) {
    if ( _.isNil( err[ prop ] ) ) continue;
    if ( _.includes( temporaries, err[ prop ] ) ) return true;
  }
  const regexes = [
    /\btimeout\b/ui,
  ];
  for ( const regex of regexes ) {
    if ( regex.test( err.message ) ) return true;
  }
  return false;
}

export function isHttpStatusCode( val ) {
  return _.isInteger( val ) && val >= 200 && val <= 599;
}
export function findHttpStatusCode( err ) {
  for ( const x of [ 'statusCode', 'code', 'status' ] ) {
    if ( isHttpStatusCode( err[ x ] ) ) return err[ x ];
  }
}

const writer = BUILD.isServer
  ? process.stderr.write.bind( process.stderr )
  : console.log.bind( console ); // eslint-disable-line no-console

export type LogErrorOptions = {
  colors?: boolean;
} | boolean;
export function logError( err: string|Error, options: LogErrorOptions = {} ) {
  const opts = _.isBoolean( options ) ? { colors : true } : options;

  if ( _.isString( err ) ) {
    writer( err );
  } else {
    writer( formatError( err, { colors : true, ...opts } ) );
  }
}

export type FormatErrorOptions = {
  colors?: boolean;
};
export function formatError( error: AnyError, opts: FormatErrorOptions = {} ) {
  if ( opts.colors ) {
    if ( isSSPError( error ) ) {
      return error.formatDetails();
    } else {
      const sym = chalk.red.bold( symbols.logging.error );
      const msg = chalk.white.bold( error.message );
      return `${sym} ${msg}\n${error.stack}\n`;
    }
  } else {
    if ( isSSPError( error ) ) {
      return error.formatDetails();
    } else {
      return String( error.stack || error );
    }
  }
}

export function isNotFoundError( error: AnyError ) {
  if ( ! isAnyError( error ) ) return false;
  return error.code === 404
    || error.status === 'not-found'
    || error.status === 404;
}

export function getKinds( error: Error ): string[] {
  const kinds: string[] = [];
  let iter: any = error;
  while ( iter && iter !== Error && iter !== Object ) {
    kinds.push( iter.constructor.name );
    iter = Object.getPrototypeOf( iter );
  }
  return _.without( _.uniq( kinds ), 'Object', 'Function' );
}
