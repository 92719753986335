import { addJsonType } from '../json/registry';
import { Stack } from './Stack';

addJsonType( {
  key : 'Stack',
  encode( stack: Stack ) {
    if ( stack instanceof Stack ) return stack.toJSON();
  },
  decode( data ) { return Stack.fromJSON( data ); },
} );

