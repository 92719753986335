import _ from 'lodash';
import {
  isPrimitive, isArray, isString, isPlainObject, isRecord,
} from '../types';
import { getSecrets } from './registry';

// We replace the secrets with Unicode biohazard symbols, which end up
// looking an awful lot like asterisks, but are much easier to detect
// when you want to ensure that log output isn't attempting to include
// secrets.
export const repchar = '\u2623';

/**
 * Given a string or data structure, attempt to scrub it of all secrets.
 * Note that class instances can't reasonably be scrubbed, so they
 * will be stringified first.
 *
 * @param data - The data to scrub.  If an object or array will be
 * recursively scrubbed.
 * @returns A copy of the original data, with secrets scrubbed.
 */
export function scrubSecrets( data ) {
  if ( isPrimitive( data ) && ! isString( data ) ) return data;
  if ( isArray( data ) ) return data.map( scrubSecrets );
  if ( isPlainObject( data ) ) {
    return Object.fromEntries( Object.entries( data ).map( scrubSecrets ) );
  }
  if ( ! isString( data ) ) data = String( data );
  for ( const secret of getSecrets() ) {
    while ( data.includes( secret ) ) {
      data = data.replace( secret, repchar.repeat( secret.length ) );
    }
  }
  return data;
}

/**
 * Redact properties in an object. The "keys" argument can be an array
 * of keys, or an object where the keys are keys to be redacted and
 * the values are either a string to replace that key with, or
 * undefined (in which case it will use the default redaction string).
 *
 * The default redaction string is a string of unicode biohazard
 * symbols the same length as the redacted string.
 *
 * @param obj - The object to redact.
 * @param keys - The keys to redact.
 * @param replacement - The string to replace redacted properties with.
 */
export function redactProperties(
  obj: Record<string, any>,
  keys: string | string[] | Record<string, string>,
  replacement?: string,
) {
  if ( isString( keys ) ) {
    if ( _.has( obj, keys ) ) {
      const rep = replacement || repchar.repeat( _.get( obj, keys ).length );
      _.set( obj, keys, rep );
    }
  } else if ( isArray( keys ) ) {
    for ( const key of keys ) redactProperties( obj, key, replacement );
  } else if ( isRecord( keys ) ) {
    for ( const [ key, repl ] of Object.entries( keys ) ) {
      redactProperties( obj, key, repl || replacement );
    }
  } else {
    throw new TypeError( `Invalid redactions '${keys}'` );
  }
}

/**
 * Given a string, check to see if it contains any bare secrets.
 *
 * @param text - The data to check.
 */
export function containsBareSecrets( text: string ) {
  return getSecrets().some( secret => text.includes( secret ) );
}
/**
 * Given a string, check to see if it contains any scrubbed secrets.
 *
 * @param text - The data to check.
 */
export function containsScrubbedSecrets( text: string ) {
  return text.includes( repchar );
}
