import { Timeout } from '../errors';
import { parseTimeout, TimeoutOptions } from '../duration';

export interface PromiseTimeoutOptions extends TimeoutOptions {
  fallback?: () => any;
  error?: Error;
  message?: string;
}

export interface CancelablePromise<T=unknown> extends Promise<T> {
  cancel(): void;
}

export function timeout<T=unknown>(
  time: string | number | PromiseTimeoutOptions,
  promise: CancelablePromise<T> | Promise<T>,
  opts: PromiseTimeoutOptions = {},
): CancelablePromise<T> {
  const ms = parseTimeout( time, opts );

  if ( typeof promise === 'function' ) {
    throw new Error( `Calling timeout with a function is deprecated` );
  }

  const htime = ( typeof time === 'object' ) ? time.timeout : time;
  let timer;
  const _promise = new Promise( ( resolve, reject ) => {

    timer = setTimeout( () => {
      if ( typeof opts.fallback === 'function' ) {
        try {
          resolve( opts.fallback() );
        } catch ( error ) {
          reject( error );
        }
      } else {
        const err = opts.error || new Timeout(
          opts.message || `Promise timed out after ${htime}`,
        );
        clearTimeout( timer );
        if ( typeof ( promise as any ).cancel === 'function' ) {
          ( promise as any ).cancel();
        }
        reject( err );
      }
    }, ms );

    ( async () => {
      try {
        resolve( await promise );
      } catch ( error ) {
        reject( error );
      } finally {
        clearTimeout( timer );
      }
    } )();
  } );
  return Object.assign( _promise, {
    cancel() { clearTimeout( timer ); },
  } ) as CancelablePromise<T>;
}
