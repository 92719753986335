import _ from 'lodash';

export const mergers = {
  values( items ) { return _.values( items ); },
  merge( items ) { return _.assign( {}, ..._.values( items ) ); },
  squish( items ) {
    items = _.values( items );
    items = _.flattenDeep( items );
    items = _.uniq( items );
    items = _.compact( items );
    return items;
  },
} as const;
export type Mergers = typeof mergers;
export type MergerName = keyof Mergers;
export type MergerFn<Output = unknown, Input = unknown>
  = ( items: Input[] ) => Output;
export type Merger<Output = unknown, Input = unknown>
  = MergerName | MergerFn<Output, Input>;

export function getMerger( arg: Merger ): MergerFn {
  if ( typeof arg === 'function' ) return arg;
  if ( mergers[ arg ] ) return mergers[ arg ];
  throw new Error( `Unknown registry merger function "${arg}"` );
}
