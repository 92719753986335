import { addErrorType, addErrorSelectors } from './serialization';
import * as TYPES from './error-types';

export type ErrorTypes = typeof TYPES;
export type ErrorTypeName = keyof ErrorTypes;

for ( const err of Object.values( TYPES ) ) addErrorType( err );

addErrorSelectors( {
  BadRequest        : 400,
  NotAuthenticated  : 401,
  PaymentError      : 402,
  Forbidden         : 403,
  NotFound          : 404,
  MethodNotAllowed  : 405,
  NotAcceptable     : 406,
  Timeout           : 408,
  Conflict          : 409,
  Gone              : 410,
  LengthRequired    : 411,
  Unprocessable     : 422,
  TooManyRequests   : 429,
  GeneralError      : 500,
  NotImplemented    : 501,
  BadGateway        : 502,
  Unavailable       : 503,
} );
