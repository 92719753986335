import './types';

import {
  jsonSerialize, jsonDeserialize, jsonParse, jsonStringify,
} from './core';

export * from './core';
export * from './registry';

export const json = {
  stringify : jsonStringify, parse : jsonParse,
  serialize : jsonSerialize, deserialize : jsonDeserialize,
};
