import _ from 'lodash';
import addrs from 'email-addresses';

/**
 * Return true if the value is likely an email.  Note that this is
 * a simple regex match, and email addresses are very difficult to
 * validate with a regex matching, so while it's not perfect it should
 * be "good enough".  The regex being used is similar to what the W3C
 * indicates for `<input type="email">`.
 *
 * If you want to see why using a regexp to strictly validate email
 * addresses is so painful, take a look at the regexp found here:
 * http://www.ex-parrot.com/pdw/Mail-RFC822-Address.html
 *
 * @param {string} value - the value to check
 * @returns {boolean} whether the value is likely an email address.
 */
export function isEmail( value: string ): boolean {
  if ( typeof value !== 'string' ) return false;
  const [ username, domain, ...extra ] = value.split( '@' );
  if ( extra.length ) return false;
  return /^[\w.!#$%&'*+/=?^`{|}~-]+$/u.test( username )
    && /^[a-z0-9-]+(?:\.[a-z0-9-]+)*$/ui.test( domain )
    && /^.+\.(xn--[a-z0-9-]+|[a-z]+)$/ui.test( domain );
}
/**
 * Parse a list of data into emails
 *
 * @param input - A delimited string of emails
 * @returns An array of emails
 */
export function extractEmails( input: string ): string[] {
  return _.uniq( input.split( /[\s,;\n<>]+/gums )
    .filter( x => x.includes( '@' ) )
    .map( x => addrs.parseOneAddress( { input : x, startAt : 'mailbox' } ) )
    .filter( x => x?.type === 'mailbox' )
    .map( x => _.get( x, 'address' ) )
    .filter( isEmail ) );
}
