import _ from 'lodash';
import { Hook } from './Hook';
import { mkdebug } from '../mkdebug';
import getCallerFile from 'get-caller-file';

const debug = mkdebug( 'ssp:utils:hooks:hooks' );

/**
 * A hooks library, for providing plugins with tapable hooks.
 *
 * @example
 * import { Hooks } from '@ssp/utils';
 *
 * export class MyClass {
 *   hooks = new Hooks( [
 *     { name : 'start', sync : false },
 *     { name : 'stop', sync : false },
 *     { name : 'config', sync : false, waterfall : true, args : [ 'data' ] },
 *   ] );
 *   async start() { await this.hooks.call( 'start' ); }
 *   async stop() { await this.hooks.call( 'stop' ); }
 *   async config( conf ) {
 *     conf = await this.hooks.call( 'config', conf );
 *     Object.assign( this, conf );
 *   }
 * }
 *
 * // Meanwhile, in an undisclosed location
 * const instance = new MyClass();
 * instance.hook.tap( 'config', async ( conf ) => {
 *   const moreConfig = await this.getMoreConfig();
 *   return _.merge( conf, moreConfig );
 * } );
 */
export class Hooks {
  static Hook = Hook;

  constructor( opts ) {
    if ( _.isArray( opts ) ) this.add( opts );
  }

  /**
   * Add a new hook configuration to the registry.
   *
   * @param {object} opts - Options object.
   */
  add( opts ) {
    if ( _.isArray( opts ) ) return _.map( opts, o => this.add( o ) );
    const hook = new Hook( opts );
    if ( this[ hook.name ] ) {
      throw new Error( `A hook named ${hook.name} is already registered` );
    }
    this[ hook.name ] = hook;
    return hook;
  }

  /**
   * Attach a handler to a hook.
   *
   * @param {string} name - Hook name to attach to.  If this is an
   * object and there is no second argument, then it's assumed to be
   * a mapping of hook names to their configurations.
   * @param {object} opts - Hook configuration.
   */
  tap( name, opts ) {
    const moreopts = { from : getCallerFile() };
    if ( _.isPlainObject( name ) && ! opts ) {
      _.each( name, ( conf, hook ) => {
        this.get( hook ).tap( conf, moreopts );
      } );
    } else if ( _.isString( name ) && opts ) {
      this.get( name ).tap( opts, moreopts );
    } else {
      throw new TypeError( `tap: Invalid config` );
    }
  }
  /**
   * Call a hook.
   *
   * @param {string} name - Hook name to call.
   * @param {any[]} args - Arguments to pass to the hooks.
   */
  call( name, ...args ) { return this.get( name ).call( ...args ); }

  get( name ) {
    if ( _.isString( name ) ) {
      if ( this[ name ] instanceof Hook ) return this[ name ];
      throw new Error( `${name} is not a valid hook name` );
    }
    throw new Error( `Cannot get hook without name` );
  }

  has( name ) {
    return _.isString( name ) && ( this[ name ] instanceof Hook );
  }

  toString() { return `Hooks`; }

}
