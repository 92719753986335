import _ from 'lodash';
import { create, get, StorePublic, CreateOptions } from './create';
import { ReducerName, ReducerFn, getReducer } from './reducers';

import type { GlobalStores } from '.';

/**
 * A store provides a global warehouse of data that you can watch and
 * be notified of when it is updated.  It's very similar to a global
 * version of React's `useState` or `useReducer` hooks, except it can
 * be used outside of a React component.
 */
export type Store<State=unknown, Input=State> = StorePublic<State, {
  /**
   * Update the state of the store.  The shape of the action argument
   * and how it is used to update the store state depends on the
   * reducer selected.
   */
  setState( action: Input, callback?: ( state: State ) => void ): void;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type StoreOptions<
  State = unknown,
  Input = State,
  Public extends Record<string, any> = Record<string, unknown>
> = CreateOptions<State, Public> & {
  /**
   * A function that takes the current state and an action and reduces
   * them into the new state.
   */
  reduce?: ReducerName | ReducerFn<State, Input>;
  /** The initial state. */
  state?: State;
};

export function createStore<
  State = unknown,
  Input = State
>( opts: StoreOptions<State, Input> ): Store<State, Input> {
  if ( ! _.isPlainObject( opts ) ) {
    throw new TypeError( `createStore requires options object` );
  }
  return create( {
    ...opts,
    reduce : getReducer( opts.reduce || ( opts as any ).reducer || 'replace' ),
    public : {
      setState( action, callback ) {
        this.debug( 'setState:', action, callback );
        const new_state = this.reduce( this.state, action );
        this.debug( 'reduced new_state:', new_state );
        // Don't trigger updates if the same state instance was returned.
        if ( Object.is( this.state, new_state ) ) {
          this.debug( '  -> unchanged, skipping' );
          return;
        }
        this.state = new_state;
        const size = this.setters.size;
        let idx = 1;
        for ( const consumer of this.setters.values() ) {
          this.debug( '  -> notifying consumer # %d of %d', idx++, size );
          consumer( this.state );
        }
        this.debug( '  -> notified %d watchers', this.setters.size );
        if ( typeof callback === 'function' ) callback( this.state );
      },
      ...opts.public,
    },
  } );
}

export function getStore<
  Name extends keyof GlobalStores,
>( name: Name ): GlobalStores[Name] extends [infer S, infer I]
  ? Store<S, I> | undefined
  : Store<GlobalStores[Name]> | undefined;
export function getStore<
  State = unknown,
  Input = State
>( name: string ): Store<State, Input> | undefined;
export function getStore( name: string ) { return get( 'Store', name ); }
