import _ from 'lodash';
import { Handler, HandlerOptions } from './Handler';
import { mkdebug } from '../mkdebug';
import { hideProps } from '../props';

const debug = mkdebug( 'ssp:utils:hooks:hook' );

/**
 * An instance of a single hook, from the Hooks library.
 */
export class Hook {

  static Handler = Handler;

  /**
   * If true then the handlers will be run synchronously.  This also means
   * it will throw if any handler returns a promise.
   *
   * @default true
   */
  sync: boolean = true;
  /**
   * If true then the handlers will be run in serial.
   *
   * @default false
   */
  serial: boolean = true;
  /**
   * If true then hooks called with the wrong number of arguments will
   * throw.
   *
   * @default false
   */
  strict: boolean = false;
  /**
   * If true then handlers that throw an error will be retried
   * repeatedly until they succeed.
   *
   * @default false
   */
  loop: boolean = false;
  /**
   * If true then the handlers will be run in a "waterfall", meaning
   * the value that was passed to the `hook.call` method will be
   * provided as the argument for the first handler, and when it is
   * done then it's return value will be provided as the argument for
   * the following handler.
   *
   * @default false
   */
  waterfall: boolean = false;
  /**
   * The names of the arguments, used primarily in producing error
   * messages if the hook is called without enough arguments.
   *
   * @default []
   */
  args: string[] = [];

  /**
   * Maximum number of times to try the loop when using `loop: true`.
   *
   * @default 10
   */
  loopLimit: number = 10;

  bail: boolean = false;

  name?: string;

  declare handlers: Handler[];

  constructor( ...args ) {
    hideProps( this, { handlers : [] } );
    for ( const arg of args ) {
      if ( _.isString( arg ) && ! this.name ) {
        this.name = arg;
      } else if ( _.isPlainObject( arg ) ) {
        _.assign( this, arg );
      } else {
        throw new Error( `Invalid argument to Hook: ${arg}` );
      }
    }
  }

  /**
   * Tap this hook, adding Handler that will be run when the hook is
   * executed.
   *
   * @param {(string|object|Function)[]} args - The hook arguments.
   * If a string is provided it will be used as the `from` value.  If
   * a plain object, it will be passed to the `Handler` constructor,
   * and if a function it will be treated as the `handler` option.
   */
  tap( ...args ) {
    const opts: Partial<HandlerOptions> = {};
    for ( const arg of args ) {
      if ( _.isString( arg ) && ! opts.from ) {
        opts.from = arg;
      } else if ( _.isPlainObject( arg ) ) {
        _.defaults( opts, arg );
      } else if ( _.isFunction( arg ) && ! opts.handler ) {
        opts.handler = arg;
      } else {
        throw new Error( `Invalid argument to Hook.tap: "${arg}"` );
      }
    }
    const handler = new Handler( opts as HandlerOptions );
    this.handlers.push( handler );
    debug( `${this}: TAPPED ${handler}` );
    return handler;
  }

  /**
   * Call this hook, executing all of it's handlers.
   *
   * @param args - The arguments to pass to the handlers.
   */
  call( ...args: any[] ) {
    if ( this.strict && args.length !== this.args.length ) {
      let msg = `Invalid call to ${this}.call: `;
      if ( args.length < this.args.length ) {
        const missing = this.args.slice( args.length );
        if ( missing.length === 1 ) {
          msg += `missing argument ${missing[0]}`;
        } else if ( missing.length > 1 ) {
          msg += `missing arguments ${missing}`;
          throw new Error( `${msg} missing arguments ${missing}` );
        }
      } else if ( args.length > this.args.length ) {
        const got = args.length;
        const exp = this.args.length;
        msg += `expected ${exp} arguments, but got ${got}`;
        if ( exp ) msg += ` (expected ${this.args})`;
      }
      throw new Error( msg );
    }
    const method = _.compact( [
      'call',
      this.sync ? 'Sync' : 'Async',
      this.serial ? 'Serial' : 'Parallel',
      this.loop && 'Loop',
      this.waterfall && 'Waterfall',
    ] ).join( '' );
    if ( typeof this[ method ] !== 'function' ) {
      throw new Error( `Invalid hook ${this.name} resolved to ${method}` );
    }
    debug( `${this}: DISPATCHING ${method} TO HANDLERS -` );
    _.each( this.handlers, handler => debug( `    ${handler}` ) );
    return this[ method ]( ...args );
  }

  callSyncSerial( ...args ) {
    for ( const handler of this.handlers ) {
      debug( `${this}: callSyncSerial ${handler}` );
      const res = handler.executeSync( this.name, args );
      if ( this.bail && res ) return res;
    }
  }

  callSyncSerialLoop( ...args ) {
    if ( this.bail ) throw new Error( `Cannot bail from loop hook` );
    const handlers = [ ...this.handlers ];
    let loops = this.loopLimit;
    const errors = new Map();
    while ( handlers.length ) {
      if ( ! loops-- ) {
        throw new Error( `Looping hook ${this.name} exceeded max attempts` );
      }
      for ( const handler of handlers ) {
        debug( `${this}: callSyncSerialLoop ${handler}` );
        try {
          handler.executeSync( this.name, args );
          _.pull( handlers, handler );
        } catch ( err ) {
          debug( `${handler.name} threw: ${err.message}` );
          errors.set( handler, err );
        }
      }
    }
  }

  callSyncSerialWaterfall( ...args ) {
    if ( this.bail ) throw new Error( `Cannot bail from waterfall hook` );
    let data = args.length ? args.shift() : {};
    for ( const handler of this.handlers ) {
      debug( `${this}: callSyncSerialWaterfall ${handler}` );
      const res = handler.executeSync( this.name, [ data, ...args ] );
      data = res;
    }
    return data;
  }

  async callAsyncSerial( ...args ) {
    for ( const handler of this.handlers ) {
      debug( `${this}: callAsyncSerial ${handler}` );
      const res = await handler.executeAsync( this.name, args );
      if ( this.bail && res ) return res;
    }
  }

  async callAsyncParallel( ...args ) {
    const promises = _.map( this.handlers, async handler => {
      debug( `${this}: callAsyncParallel ${handler}` );
      await handler.executeAsync( this.name, args );
    } );
    if ( this.bail ) return Promise.race( promises );
    return Promise.all( promises );
  }

  async callAsyncSerialLoop( ...args ) {
    if ( this.bail ) throw new Error( `Cannot bail from loop hook` );
    const handlers = [ ...this.handlers ];
    const errors = new Map();
    while ( handlers.length ) {
      for ( const [ idx, handler ] of Object.entries( handlers ) ) {
        debug( `${this}: callAsyncSerialLoop ${handler} (${idx})` );
        try {
          await handler.executeAsync( this.name, args );
          _.pull( handlers, handler );
        } catch ( err ) {
          debug( `${handler.name} threw: ${err.message}` );
          errors.set( handler, err );
        }
      }
    }
  }

  async callAsyncSerialWaterfall( ...args ) {
    let data = args.length ? args.shift() : {};
    for ( const handler of this.handlers ) {
      debug( `${this}: callAsyncSerialWaterfall ${handler}` );
      data = await handler.executeAsync( this.name, [ data, ...args ] );
    }
    return data;
  }

  toString() {
    return `Hook(${this.name}->${this.handlers.length})`;
  }
}
