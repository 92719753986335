
export type PromisesProgressCallback = (
  progress: number,
  complete: number,
  total: number,
) => any | Promise<any>;

export async function promisesProgress(
  iterable: Iterable<any>,
  onprogress: PromisesProgressCallback,
) {
  const promises = await Promise.resolve( iterable ).then( iter => {
    return Array.from( iter ).map( x => Promise.resolve( x ) );
  } );
  let resolved = 0;

  const emit = increment => {
    resolved += increment;
    const progress = ( resolved && promises.length )
      ? Math.floor( ( promises.length / resolved ) * 100 ) : 0;
    return Promise.resolve( onprogress( progress, resolved, promises.length ) );
  };

  // emit a starting progress event for 0
  await emit( 0 );

  // emit a progress event each time a promise resolves
  return Promise.all(
    promises.map( promise => promise.finally( () => emit( 1 ) ) ),
  );
}
