/**
 * These are IDs that are in use for various special purposes but that
 * don't meet the criteria matched by `isId`.
 */
const special_ids = [
  'ORPHANAGE',
  'SSP',
  'SYSTEM',
  'TED-TESTER',
  'TEST-BAH-GROUP',
  'TEST-CONFLUENCE-SPACE',
  'TEST-CSNAD-GROUP',
  'TEST-DEVS-TEAM',
  'TEST-GITHUB-ORG',
  'TEST-GITHUB-REPO',
  'TEST-GITHUB-TEAM',
  'TEST-JIRA-PROJECT',
  'TEST-LINKLESS-TEAM',
  'TEST-SLACK-CHANNEL',
  'TEST-SLACK-USERGROUP',
  'TEST-SLACK-WORKSPACE',
  'TEST-TESTERS-TEAM',
  'TEST-PROJECT',
  'TEST-THING-1',
  'TEST-THING-2',
  'TEST-THING-3',
];

export type Id = string;

export function isId( value: any ): value is Id {
  // Originally we used shortid, which produce 7-14 characters from
  // the base64 set.  We eventually changed to re-encoding into base62
  // (so they were only alphanumeric) and then switched to nanoid
  // using the same alphabet, so here we still need to detect those
  // original shortid types until they have entirely disappeared from
  // the datbase.
  return isNanoidId( value )
    || isSpecialId( value )
    || isShortidId( value );
}
export function isShortidId( value ) {
  return /^[A-Za-z0-9-_]{7,14}/u.test( value );
}
export function isNanoidId( value ) {
  return /^[A-Za-z0-9]{16}/u.test( value );
}
export function isSpecialId( value ) { return special_ids.includes( value ); }
export function isID( value ) { return isId( value ); }
