import _ from 'lodash';
import { Duration } from 'luxon';
import { isString, isNumber } from './types';
import humanizeDuration from 'humanize-duration';

const units_map = {
  milliseconds  : 'milliseconds',
  millisecond   : 'milliseconds',
  ms            : 'milliseconds',
  seconds       : 'seconds',
  second        : 'seconds',
  sec           : 'seconds',
  secs          : 'seconds',
  s             : 'seconds',
  minutes       : 'minutes',
  minute        : 'minutes',
  min           : 'minutes',
  mins          : 'minutes',
  m             : 'minutes',
  hours         : 'hours',
  hour          : 'hours',
  hrs           : 'hours',
  hr            : 'hours',
  h             : 'hours',
  days          : 'days',
  day           : 'days',
  d             : 'days',
  weeks         : 'weeks',
  week          : 'weeks',
  wks           : 'weeks',
  wk            : 'weeks',
  w             : 'weeks',
  months        : 'months',
  month         : 'months',
  M             : 'months',
  quarters      : 'quarters',
  quarter       : 'quarters',
  qtrs          : 'quarters',
  qtr           : 'quarters',
  q             : 'quarters',
  years         : 'years',
  year          : 'years',
  yrs           : 'years',
  yr            : 'years',
  y             : 'years',
} as const;

export type TimeoutUnit = ( keyof typeof units_map )[number];

const aliases = {
  hourly      : '1 hour',
  daily       : '1 day',
  weekly      : '1 week',
  monthly     : '1 month',
  yearly      : '1 year',
  fortnightly : '2 weeks',
} as const;
const reverse_aliases = _.invert( aliases );

/**
 * A timeout specifier.  Either a number of milliseconds, or a string
 * that can be parsed into a timeout (or a function that produces one
 * of those).
 */
export type TimeoutSpec = string | number;

export interface TimeoutOptions {
  /**
   * You can specify the timeout as one of the options, passing an
   * object instead of a number or string.
   */
  timeout?: TimeoutSpec;
  /** Use this value if parsing the input does not produce a number. */
  default?: TimeoutSpec;
  /** If the result is less than this then return this value instead. */
  min?: TimeoutSpec;
  /** Alias for `min`. */
  minimum?: TimeoutSpec;
  /** If the result is more than this then return this value instead. */
  max?: TimeoutSpec;
  /** Alias for `max`. */
  maximum?: TimeoutSpec;
  /** Divide resulting time in half, useful for heartbeat calculations. */
  half?: boolean;
  /** Use Math.floor on the result. */
  floor?: boolean;
  /** Use Math.ceil on the result. */
  ceil?: boolean;
 /**
  * Provide an alternate unit for the result to be returned as.  This
  * makes it easy to get timeouts in seconds, for example.
  * If not specified, the default is 'milliseconds'.
  */
  units?: TimeoutUnit;
  /** Alias for units. */
  unit?: TimeoutUnit;
  /** Alias for units. */
  as?: TimeoutUnit;
 /**
  * Provide a function that takes a number and returns another number,
  * in case you need to modify the result.  The function will be
  * called with the value that would be returned otherwise (so it will
  * be in the unit specified by the units option, for example).
  */
  modify?: ( val: number ) => number;
  /**
   * Provide a time period and the parsed timeout will have a random
   * amount between 0 and this value added to it.
   */
  vary?: TimeoutSpec;
}
function isTimeoutOptions( value: any ): value is TimeoutOptions {
  return _.isPlainObject( value );
}

/**
 * Parse a timeout value, turning it from a string ("30s", "5minutes",
 * etc) into an integer (in milliseconds).
 *
 * If you include `min` and/or `max` values in the options, they will
 * also be parsed as timeouts and then used to clamp the return value.
 *
 * Note that when given a numeric time, if the value is less than 300
 * then it's assumed to be seconds rather than milliseconds.
 *
 * @param time - Time to parse.
 * @param opts - Options.
 * @returns Timeout length in milliseconds (or the unit specified by
 * `units`).
 * @memberof utils
 */ // eslint-disable-next-line complexity
export function parseTimeout(
  time_or_opts: TimeoutSpec | TimeoutOptions,
  add_opts?: TimeoutOptions,
) {
  const opts = isTimeoutOptions( time_or_opts ) ? time_or_opts : add_opts || {};
  const time = isTimeoutOptions( time_or_opts ) ? opts.timeout : time_or_opts;

  const parsed = parseDuration( time );
  const dflt = parseDuration( opts.default );
  // This is intentionally parsing both values before choosing one, so
  // that you find out early if specifying an invalid default.
  let duration = parsed || dflt || Duration.fromMillis( 0 );

  if ( opts.min || opts.minimum ) {
    const min = parseTimeout( opts.min || opts.minimum );
    if ( min && duration.as( 'milliseconds' ) < min ) {
      duration = Duration.fromMillis( min );
    }
  }
  if ( opts.max || opts.maximum ) {
    const max = parseTimeout( opts.max || opts.maximum );
    if ( max && duration.as( 'milliseconds' ) > max ) {
      duration = Duration.fromMillis( max );
    }
  }
  if ( opts.half ) duration = duration.mapUnit( x => x / 2 );
  if ( opts.vary ) {
    const vary = parseDuration( opts.vary );
    if ( _.isNumber( vary ) ) duration += _.random( vary );
  }
  const raw_unit = opts.units || opts.unit || opts.as;
  if ( raw_unit ) {
    const unit = units_map[ raw_unit ];
    if ( ! unit ) throw new Error( `Invalid duration unit "${raw_unit}"` );
    duration = duration.as( unit );
  } else {
    duration = duration.as( 'milliseconds' );
  }
  if ( opts.floor ) duration = Math.floor( duration );
  if ( opts.ceil ) duration = Math.ceil( duration );
  if ( typeof opts.modify === 'function' ) duration = opts.modify( duration );
  return duration;
}

// eslint-disable-next-line complexity
export function parseDuration( time: TimeoutSpec ) {
  const orig = time;
  if ( time && isNumber( time ) ) {
    return Duration.fromObject( {
      [ time < 300 ? 'seconds' : 'milliseconds' ] : time,
    } );
  }
  if ( ! isString( time ) ) return;
  const obj = {};
  if ( aliases[ time ] ) time = aliases[ time ];
  if ( _.isString( time ) ) {
    for ( const match of time.matchAll( /(\d+(?:\.\d+)?)\s*([a-z]+)/giu ) ) {
      const units = units_map[ match[2] ];
      const num = match[1].includes( '.' )
        ? parseFloat( match[1] ) : parseInt( match[1] );
      if ( isString( units ) && isNumber( num ) ) obj[ units ] = num;
    }
  }
  if ( ! Object.keys( obj ).length ) return;
  const duration = Duration.fromObject( obj );
  if ( ! duration.isValid ) {
    const expl = duration.invalidExplanation;
    throw new Error( `Invalid duration "${orig}": ${expl}` );
  }
  if ( ! duration.as( 'milliseconds' ) ) return;
  return duration;
}

export const duration = {
  parse     : parseTimeout,
  humanize  : humanizeDuration,
};
export { Duration, humanizeDuration };

export function humanizeInterval( dur, opts ) {
  const when = humanizeDuration( dur, opts );
  return reverse_aliases[ when ] || when;
}
