import _ from 'lodash';
import { isPrimitive, isFunction } from '../types';
import stableStringify from 'json-stable-stringify';
import { getJsonType, encodeJsonType } from './registry';
import { scrubSecrets } from '../secrets';
import { mkdebug } from '../mkdebug';
import { log } from '~/log';

export type JsonPrimitive = string | number | boolean;
export type JsonArray = ArrayLike<Json>;
export type JsonObject = { [key: string]: Json; };
export type Json = JsonPrimitive | JsonArray | JsonObject;

const debug = mkdebug( 'ssp:utils:json:core' );

// NOTE: This supports two kinds of `$ref` objects:
//
// ARRAY: The value of the `$ref` may be an array, which indicates
// that it was a circular reference and the array contains the path
// through the object to get to the instance that was not replaced.
//
// OBJECT: The value can also be an object with a single key.  In this
// case it means the `$ref` was for a serializable type, and the
// single key of the object is the type identifier, and that keys
// value is the data needed to deserialize the original object.  There
// is an RFC draft that also provides for JSON refs with the same
// `$ref` key, but in that case the value is always a string
// containing a URL, so it doesn't conflict with the way we are using
// it (though we may add support for that type at some point in the
// future).
// https://tools.ietf.org/html/draft-pbryan-zyp-json-ref-03

export interface JsonSerializeOptions {
  /** Scrub secrets from the output to prevent APIs from leaking them. */
  scrub?: boolean;
  /** JSON replacer function. */
  replacer?: <R = unknown, T = unknown>( value: T, path: string ) => R,
}

/**
 * Return the serialized JSON format of an object.  This is basically
 * the step right before stringification, with circular references
 * and serializable instances replaced with `$ref` markers, but
 * without the stringification piece.
 *
 * @param data - The value to serialize.
 * @param options - Options object.
 * @returns - The transformed object.
 */
export function jsonSerialize(
  data: any, options: JsonSerializeOptions = {},
): Json {
  const { scrub = true, replacer } = options;

  const refs = new WeakMap();
  const proc = ( value, path ) => {
    if ( isFunction( replacer ) ) {
      value = replacer( value, path );
      if ( _.isNil( value ) ) return;
    }

    // If it's a string we want to scrub secrets if we're doing that
    // sort of thing.
    if ( _.isString( value ) ) {
      if ( scrub ) return scrubSecrets( value );
      return value;
    }

    // If it's any other kind of primitive then we don't need to do
    // anything with it.
    if ( isPrimitive( value ) ) return value;

    // If it's a reference to an object we've already seen then we
    // just replace it with a path reference pointing to the
    // previously serialized copy
    const dup = refs.get( value );
    if ( _.isArray( dup ) ) return { $ref : dup };

    // If it wasn't a duplicate then we store a reference to it in
    // case we find a duplicate of it later.
    refs.set( value, path );

    // Try to encode it, if that succeeds then we replace the value
    // with the encoded value
    const encoded = encodeJsonType( value );
    if ( encoded ) {
      value = encoded[1];

      // We check for primitives again, in case the encoder returned
      // a string or a number.
      if ( isPrimitive( value ) ) return { $ref : { [ encoded[0] ] : value } };
    }

    // If it has a toJSON method then we use that to transform it
    if ( isFunction( value.toJSON ) ) {
      value = value.toJSON();
      debug( 'toJSON returned:', value );
      // Then we check again to see if the thing it returned is a dup
      const $ref = refs.get( value );
      if ( _.isArray( $ref ) ) return { $ref };
    }

    // If it's an array then we process each of it's elements
    if ( _.isArray( value ) ) {
      value = value.map( ( e, i ) => proc( e, path.concat( i ) ) );
    }

    // If it's an object, then we process all of it's values
    if ( _.isPlainObject( value ) ) {
      value = _.mapValues( value, ( v, k ) => proc( v, path.concat( k ) ) );
    }

    // If it was encoded then we need to return it with it's ref tag
    return encoded ? { $ref : { [ encoded[0] ] : value } } : value;
  };
  return proc( data, [] );
}


export interface JsonStringifyOptions extends JsonSerializeOptions {
  /** How many spaces to indent. */
  spaces?: number;
  /** Alias for spaces */
  space?: number;
  /** Sorting function.
   *  See https://www.npmjs.com/package/json-stable-stringify#cmp
   */
  cmp?: (
    a: { key: string|number|symbol, value: any },
    b: { key: string|number|symbol, value: any },
  ) => number;
}

/**
 * Just like the `JSON.stringify` function, but with these added
 * features:
 *  - will not choke on circular references
 *  - transforms some complicated objects into suitable references
 *  - stable output
 *
 * Note that this function can take the same style of arguments as
 * `JSON.stringify` (`stringify( whatever, replacerFunction, 2 )`),
 * but it's strongly recommended you use the options object version
 * instead.
 *
 * @example
 * const text = jsonStringify( whatever, {
 *   replacer : handleReplacements,
 *   space    : 2,
 * } );
 * @param {any} object - The object to stringify.
 * @param options - Options object.
 * @returns {string}
 */
export function jsonStringify(
  object: any, options: JsonStringifyOptions = {},
) {
  const { spaces, space, cmp, ...opts } = options;
  const sp = spaces ?? space ?? ( BUILD.isProd ? 0 : 2 );
  return stableStringify( jsonSerialize( object, opts ), { space : sp, cmp } );
}

export interface JsonParseOptions extends JsonDeserializeOptions {
  /** Reviver function */
  reviver?: <R = unknown, T = unknown>( data: T ) => R,
}

/**
 * Just like the `JSON.parse` function, but with these added features:
 *  - will re-create circular references produced by #stringify
 *  - transforms references produced by #stringify back into
 *    complicated objects.
 *
 * @example
 * const obj = jsonParse( text, {
 *   reviver : handleRevivification,
 * } );
 * @param text - The text to parse.
 * @param options - Options object.
 */
export function jsonParse<T = Json>(
  text: string, options: JsonParseOptions = {},
): T {
  if ( typeof options === 'function' ) options = { reviver : options };
  return jsonDeserialize( JSON.parse( text, options.reviver ), options );
}

export interface JsonDeserializeOptions {
}
/**
 * Restore an object that was transformed by jsonSerialize.
 *
 * @param {JSON} object - The object to transform.
 * @returns {any} - The modified object.
 *
 * @example
 *  const str = '[{"$ref":[]}]';
 *  return jsonDeserialize( JSON.parse( str ) );
 *  // Returns a single-element array that contains itself.
 */
export function jsonDeserialize( obj, _opts: JsonDeserializeOptions = {} ) {

  const proc_type_refs = ( value, path: ( string | number )[] = [] ) => {
    if ( isPrimitive( value ) ) return value;

    if ( _.isArray( value ) ) {
      value = _.map(
        value,
        ( val, idx ) => proc_type_refs( val, [ ...path, idx ] ),
      );
    }
    if ( _.isPlainObject( value ) ) {
      value = _.mapValues(
        value,
        ( val, key ) => proc_type_refs( val, [ ...path, key ] ),
      );
    }

    const $ref = _.isPlainObject( value )
      && _.size( value ) === 1
      && ( _.isArray( value.$ref ) || _.isPlainObject( value.$ref ) )
      && value.$ref;

    if ( _.isArray( $ref ) ) {
      // we'll handle the array values separately next...
      try {
        return _.get( obj, $ref );
      } catch ( error ) {
        log.warn( `Unable to deserialize reference:`, { $ref, obj } );
        return value;
      }
    }

    if ( _.isPlainObject( $ref ) ) {
      const entries = Array.from( Object.entries( $ref ) );
      if ( entries.length !== 1 ) {
        log.warn( `Invalid $ref: entries.length is ${entries.length}` );
        return value;
      }
      const [ key, val ] = entries[ 0 ];
      const reg = getJsonType( key );
      if ( ! reg ) {
        log.warn( `Unable to decode reference of type "${key}"` );
        return val;
      }
      return reg.decode( val as any );
    }

    return value;
  };
  return proc_type_refs( obj );
}
