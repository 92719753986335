import _ from 'lodash';

/**
 * Attach hidden (non-enumerable) properties to an object.  The
 * properties can either be specified as an object, in which case you
 * can include their values, or as a string, which will just mark the
 * property as non-enumerable without changing it's value.
 *
 * @param {object} obj - The object to attach the properties to.
 * @param {Array<string|object>} props - The props to attach.
 *
 * @example
 * hideProps( user, { someInfo : {} } );
 * hideProps( user, 'hiddenValue' );
 */
export function hideProps<T=unknown>( obj, ...props ): T {
  makeProps( obj, props, { enumerable : false } );
  return obj;
}
export function lazyProps<T=unknown>( obj: T, props, opts={} ): T {
  Object.defineProperties( obj, _.mapValues( props, ( getter, name ) => ( {
    enumerable    : false,
    configurable  : true,
    ...opts,
    get() {
      const value = getter();
      Object.defineProperty( obj, name, { value, writable : false } );
      return value;
    },
  } ) ) );
  return obj;
}

/**
 * Attach visible (enumerable) properties to an object.  The
 * properties can either be specified as an object, in which case you
 * can include their values, or as a string, which will just mark the
 * property as enumerable without changing it's value.
 *
 * @param {object} obj - The object to attach the properties to.
 * @param {Array<string|object>} props - The props to attach.
 *
 * @example
 * showProps( user, { someInfo : {} } );
 * showProps( user, 'hiddenValue' );
 */
export function showProps( obj, ...props ) {
  makeProps( obj, props, { enumerable : true } );
}

export function makeProps( obj, props, config={} ) {
  if ( _.isBoolean( config ) ) config = { enumerable : config };
  _.defaults( config, {
    enumerable    : false,
    configurable  : true,
    writable      : true,
  } );
  _.each( _.compact( _.flattenDeep( props ) ), prop => {
    if ( _.isString( prop ) ) {
      Object.defineProperty( obj, prop, { ...config, value : obj[ prop ] } );
    } else if ( _.isPlainObject( prop ) ) {
      for ( const [ key, value ] of Object.entries( prop ) ) {
        Object.defineProperty( obj, key, { value, ...config } );
      }
    } else {
      throw new TypeError( `Invalid value "${prop}" to makeProps` );
    }
  } );
}

/**
 * Given an object, search for properties where the value has the
 * `__esModule` property, which indicates that it was an ES module
 * that was loaded with `require` instead of `import`.  When these are
 * found check to see what exports they have.  If the only export is
 * named either `default` or has the same name as the key, then hoist
 * that property value up one level.
 * This is used by things like the modules index.js files to let you
 * say `schema : require( './schema')` instead of having to always use
 * `schema : require( './schema').default` or
 * `schema : require( './schema').schema`.
 *
 * @param {object} obj - The object whose proprerties should be
 * inspected.
 * @returns {object} returns an object with the imports hoisted.
 */
export function hoistDefaultImports( obj ) {
  return _.mapValues( obj, ( val, key ) => {
    val = hoistDefaultImport( val, key );
    obj[ key ] = val;
    return val;
  } );
}

export function hoistDefaultImport( obj, key ) {
  if ( ! _.isObject( obj ) ) return obj;
  if ( ! ( obj as any ).__esModule ) return obj;
  const keys = _.without( _.keys( obj ), '__esModule' );
  if ( keys.length !== 1 ) return obj;
  if ( keys[ 0 ] === 'default' ) return ( obj as any ).default;
  if ( keys[ 0 ] === key ) return obj[ key ];
  return obj;
}

export function dumpAccessors( accessors ) {
  // eslint-disable-next-line no-console
  console.log( 'ACCESSORS', _.mapValues( accessors, acc => {
    return _.mapValues( acc, x => x.toString().replace( /\n\s*\b/gu, ' ' ) );
  } ) );
  return accessors;
}

/**
 * Get a property descriptor, for a property, walking up the
 * inheritance chain to find it if necessary.
 */
export function getPropertyDescriptor( obj, name ) {
  let iter = obj;
  while ( iter ) {
    const desc = Object.getOwnPropertyDescriptor( iter, name );
    if ( desc ) return desc;
    iter = Object.getPrototypeOf( iter );
  }
}
