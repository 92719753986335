import _ from 'lodash';
import { mkdebug } from './mkdebug';
import { log } from '~/log';

const debug = mkdebug( 'ssp:utils:toposort' );

export interface TopoSortItem {
  id?: string;
  name?: string;
  before?: string|string[];
  after?: string|string[];
}
export interface TopoSortClassOptions<T extends TopoSortItem = TopoSortItem> {
  /**
   * Function to use to get the ID for
   * an item.  The default is just `item => item.id`.
  */
  getId?: ( item: T ) => string;
  /**
   * Function to use to generate an ID for an item that doesn't have one.
   * By default this just makes incrementing id's starting with `@item_1`,
   * `@item_2`, etc..
   */
  mkId?: ( item?: T ) => string;
  /**
   * Function to use to get the name of an item.  The default is
   * `item => item.name`.  Names are only used to help with debugging.
   */
  getName?: ( item: T ) => string;
  /**
   * Function to use to get the list of ids that this item should sort
   * before.  The default is `item => item.before`.  For ease of use,
   * the value returned by the `getBefore` and `getAfter` functions will
   * be compacted, uniqed, and flattened.
   */
  getBefore?: ( item: T ) => string|string[];
  /**
   * Function to use to get the list of ids that this item should sort
   * after.  The default is `item => item.after`.
   */
  getAfter?: ( item: T ) => string|string[];
}
export interface TopoSortDotOptions {
  label?: string;
  file?: string;
  virtuals?: boolean;
  pseudos?: boolean;
  implied?: boolean;
  disconnected?: boolean;
  cycles?: string[];
}

export class TopoSort<
  T extends TopoSortItem = TopoSortItem
> implements TopoSortClassOptions {

  /**
   * Adjacency list of nodes, the key is the node id, the value is a
   * Set of the ids that must be sorted before it.
   */
  nodes: Record<string, Set<string>> = {};

  /**
   * A mapping of ids to the item that was used to create them (the
   * items are the things we really want sorted, the ids are just for
   * the algorithm).
   */
  _items: Record<string, T> = {};

  _names: Record<string, string> = {};

  // Counter for creating temporary ids
  counter: number = 0;

  getId: ( item: T ) => string = item => item.id;
  mkId: ( item?: T ) => string = () => '@item_' + this.counter++;
  getName: ( item: T ) => string = item => item.name;
  getBefore: ( item: T ) => string|string[] = item => item.before;
  getAfter: ( item: T ) => string|string[] = item => item.after;

  _ids?: string[];

  constructor( options: TopoSortClassOptions<T>, items?: T[] ) {
    _.assign( this, options );
    this.order( '@start', '@_start', '@_end', '@end' );
    if ( items ) this.add( items );
  }

  node( id: string ) {
    return this.nodes[ id ] || ( this.nodes[ id ] = new Set() );
  }
  name( id: string ) { return this._names[ id ]; }

  get ids(): string[] {
    if ( ! this._ids ) this.sort();
    return this._ids.filter( id => this._items[ id ] );
  }
  get names(): string[] { return _.compact( _.at( this._names, this.ids ) ); }
  get items(): T[] { return _.compact( _.at( this._items, this.ids ) ); }
  get edges() {
    return _.flatMap( this.nodes, ( after, id ) => {
      return Array.from( after ).map( a => ( [ a, id ] ) );
    } );
  }

  get implied_edges() {
    const implied: [ string, string ][] = [];
    const ids = this.ids;
    const have_edge = ( before, after ) => this.node( before ).has( after );
    for ( let i = 1 ; i < ids.length ; i++ ) {
      if ( ! have_edge( ids[ i ], ids[ i - 1 ] ) ) {
        implied.push( [ ids[ i - 1 ], ids[ i ] ] );
      }
    }
    return implied;
  }

  get all_ids(): string[] {
    if ( ! this._ids ) this.sort();
    return this._ids;
  }

  order( ...ids ): this {
    for ( const id of ids ) this.node( id ); // Make sure everything has a node
    // Go through the IDs passed in and treat them as a series that
    // should start in the order specified
    for ( let i = 1 ; i < ids.length ; i++ ) {
      this.node( ids[ i ] ).add( ids[ i - 1 ] );
    }
    return this;
  }

  add( item ): this {
    if ( _.isNil( item ) ) return this;
    if ( Array.isArray( item ) ) {
      item.map( i => this.add( i ) );
      return this;
    }
    const id = this.getId( item ) || this.mkId( item );
    this._items[ id ] = item;
    this._names[ id ] = this.getName( item );
    delete this._ids; // Make sure we resort after adding things
    this.node( id ); // Make sure they have a node
    _.each( squish( this.getBefore( item ) ), b => this.order( id, b ) );
    _.each( squish( this.getAfter( item ) ), a => this.order( a, id ) );
    return this;
  }

  has( item ): boolean {
    const id = this.getId( item );
    if ( ! id ) return false;
    return !! this._items[ id ];
  }

  prepare(): this {
    const _end = this.node( '@_end' );
    const end = this.node( '@end' );
    _.each( this.nodes, ( ancestors, id ) => {
      // If the item doesn't explicitly specify it's order as coming
      // after @start, then we add the implicit @_start marker to it.
      if ( id !== '@start' && id !== '@_start' ) {
        if ( ! ancestors.has( '@start' ) ) ancestors.add( '@_start' );
      }
      if ( id !== '@end' && id !== '@_end' ) {
        // If the item doesn't explicitly specify it's order as coming
        // before @end, then we add it to the implicit @_end set.
        if ( ! end.has( id ) ) _end.add( id );
      }
    } );
    debug( 'PREPARED:', this.nodes );
    return this;
  }

  sort() {
    this.prepare();

    // Kahn's Algorithm
    // http://en.wikipedia.org/wiki/Topological_sorting

    const nodes = _.cloneDeep( this.nodes );
    debug( 'Sorting nodes:', nodes );
    const has_ancestors = id => _.some( nodes, n => n.has( id ) );

    // L ← Empty list that will contain the sorted elements
    const L = [];
    // S ← Set of all nodes with no incoming edge
    const S = _.reject( _.keys( nodes ), has_ancestors );
    debug( 'Starting S:', S );

    // while S is not empty do
    while ( S.length ) {
      // remove a node n from S
      const n = S.pop();
      // Add n to L
      L.unshift( n );

      if ( ! nodes[n] ) continue;
      // for each node m with an edge e from n to m do
      nodes[n].forEach( m => {
        // remove edge e from the graph
        nodes[n].delete( m );
        // if m has no other incoming edges then insert m into S
        if ( ! has_ancestors( m ) ) S.push( m );
      } );
    }
    // if graph has edges then return error (graph has at least one cycle)
    // else return L (a topologically sorted order)
    const cycles = _.omitBy( nodes, _.isEmpty );
    if ( _.isEmpty( cycles ) ) {
      if ( ! ( _.first( L ) === '@start' && _.last( L ) === '@end' ) ) {
        throw new Error( `@start should be first, @end last, found: ${L}` );
      }
      this._ids = L;
      return this.items;
    }
    const cycle_info = _.flatMap( cycles, ( after, id ) => {
      return Array.from( after ).map( pre => `"${pre}" -> "${id}"` );
    } );
    log.debug( 'Cyclic items:\n', cycle_info.join( ', ' ) );
    /*
    this.dot( {
      file : 'cyclic-items.gv', label : 'toposort_cycles',
      virtuals : true, pseudos : true, cycles : cycle_info,
    } );
    */
    throw new Error( `Graph is cyclic, cannot toposort` );
  }

  label( id ): string {
    const name = this.name( id );
    if ( ! name ) return id;
    if ( _.isString( name ) ) return `${id} (${name})`;
    return `${id} (${JSON.stringify( name )})`;
  }

  dot( opts: TopoSortDotOptions = {} ) {
    const {
      label = 'toposort',
      file = null,
      virtuals = false,
      pseudos = false,
      implied = true,
      disconnected = true,
      cycles = [],
    } = opts;
    const nodes: Set<string> = new Set();
    if ( disconnected ) this.ids.forEach( id => nodes.add( id ) );
    const show = ( id ) => {
      if ( id.startsWith( '@_' ) ) return pseudos;
      if ( id.startsWith( '@' ) ) return virtuals;
      return true;
    };
    const edges = _.compact( _.flatMap( this.edges, ( [ from, to ] ) => {
      if ( ! ( show( from ) && show( to ) ) ) return;
      nodes.add( from );
      nodes.add( to );
      const edge = `"${from}" -> "${to}"`;
      if ( cycles.includes( edge ) ) {
        return `  ${edge} [color=red];`;
      } else {
        return `  ${edge};`;
      }
    } ) );
    if ( implied ) {
      for ( const [ from, to ] of this.implied_edges ) {
        nodes.add( from );
        nodes.add( to );
        const edge = `"${from}" -> "${to}"`;
        edges.push( `  ${edge} [style=dashed];` );
      }
    }
    const configs = {
      '@start'    : { shape : 'Mdiamond' },
      '@end'      : { shape : 'Msquare' },
      '@_start'   : { shape : 'circle', height : '0.1', width : '0.1' },
      '@_end'     : { shape : 'circle', height : '0.1', width : '0.1' },
    };
    const render_node = ( id ) => {
      const name = this.name( id ) || id;
      const args = [ `label="${name}"` ];
      const config = configs[ id ];
      args.push( ..._.map( config, ( val, key ) => `${key}=${val}` ) );
      return `  "${id}" [${args}];`;
    };
    const body = [
      ...Array.from( nodes ).map( render_node ),
      ..._.map( edges, e => `  ${e}` ),
    ].join( '\n' );
    const data = `strict digraph ${label} {\n${body}\n}\n`;
    if ( BUILD.isServer && file ) {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require( 'fs' ).writeFileSync( file, data, 'utf8' );
    }
    return data;
  }

}

/**
 * @typedef {object} MetaItem
 *
 * The options passed to `toposort` can also include an array of
 * "meta" objects that are included in the sort list to allow other
 * items to refer to them to help them get sorted into the right
 * order.  These meta items can have additional properties that affect
 * how they are used by the sorting process.
 *
 * Note that the property searching methods used for finding the id,
 * before, and after values for regular items are not used for meta
 * items, they must specify them explicitly as `id`, `before`, and
 * `after`.
 *
 * There are four meta items included in the list by default:
 * `{ id : '@start', before : '@end' }`
 * `{ id : '@_start', after : '@start' }`
 * `{ id : '@_nd', before : '@end' }`
 * `{ id : '@end', after : '@start' }`
 *
 * Any item that doesn't explicitly include `@start` in it's `after`
 * list will be implicitly marked `after: '@_start'`, and any item
 * that doesn't explicitly indicate `before: '@end'` will be
 * implicitly marked `before: '@_end'`.  This way all the items that
 * don't care how close they are to one end or the other will end up
 * sorted between `@_start` and `@_end`, and items' that explicitly
 * indicated `after: '@start'` will run before everything else, and
 * those that requested `before: '@end'` will run after all of those.
 *
 * The `@start` and `@end` meta items are also special in that it's
 * a fatal error to attempt to sort something before `@start` or after
 * `@end`.
 *
 * @property {string} id - Item ID.
 * @property {string[]} [before] - The items that this item should
 * come before.
 * @property {string[]} [after] - The items that this item should
 * come after.
 */
export interface TopoSortMetaItem {
  id: string;
  before?: string[];
  after?: string[];
}

export interface TopoSortOptions extends TopoSortClassOptions {
  /**
   * By default `toposort` returns the sorted items, but you can set
   * this to return ids, names, or the `TopoSort` object itself.
   */
  result?: 'items' | 'ids' | 'names' | 'graph';
}

/**
 * Given an array of items, sort them topologically.
 *
 * @param items - The items to sort.
 * @param options - Options Object.
 */
export function toposort<T extends TopoSortItem = TopoSortItem>(
  items: T[], options?: TopoSortOptions & { result: 'items' },
): T[];
export function toposort<T extends TopoSortItem = TopoSortItem>(
  items: T[], options: TopoSortOptions & { result: 'ids' | 'names' },
): string[];
export function toposort<T extends TopoSortItem = TopoSortItem>(
  items: T[], options: TopoSortOptions & { result: 'graph' },
): TopoSort;
export function toposort<T extends TopoSortItem = TopoSortItem>(
  items: T[], options: TopoSortOptions = {},
) {
  const { result = 'items', ...opts } = options;
  if ( ! Array.isArray( items ) ) {
    throw new Error(
      `Invalid argument to toposort (expected array): ${items}`,
    );
  }

  const graph = new TopoSort( opts, items );
  graph.sort();

  if ( result === 'graph' ) return graph;
  debug( 'RETURNING', result );
  return graph[ result ];
}

function squish( ...args ) {
  return _.uniq( _.filter( _.compact( _.flattenDeep( args ) ), _.isString ) );
}

if ( BUILD.isTest ) {
  // Attach these functions to toposort when testing, so we can unit
  // test them without exposing them
  _.assign( toposort, { squish } );
}
